import axios from "axios";

import { config } from "../config/config";

const packageJson = require("../../package.json");

export const invokeApi = async (url, params, cookies) => {
  try {
    let headers = {
      "Content-Type": "application/json",

      appversion: packageJson.version,

      platform: "web",
    };

    if (
      cookies &&
      cookies[config.cookieName] &&
      cookies[config.cookieName].token &&
      cookies[config.cookieName].loginUserId
    ) {
      headers.Authorization = "Bearer " + cookies[config.cookieName].token;

      headers.loginUserId = cookies[config.cookieName].loginUserId;
    }

    if (
      cookies &&
      cookies[config.sessionCookie] &&
      cookies[config.sessionCookie].sessionId
    ) {
      headers.sessionId = cookies[config.sessionCookie].sessionId;
    }

    return await axios.post(url, params, { headers: headers });
  } catch ({ response }) {
    return response;
  }
};

export const invokeFormDataApi = async (url, formData, cookies) => {
  try {
    let headers = {
      "Content-Type": "multipart/form-data",
      appversion: packageJson.version,
      platform: "web",
    };
    if (
      cookies &&
      cookies[config.cookieName] &&
      cookies[config.cookieName].token &&
      cookies[config.cookieName].loginUserId
    ) {
      headers.Authorization = "Bearer " + cookies[config.cookieName].token;
      headers.loginUserId = cookies[config.cookieName].loginUserId;
    }
    if (
      cookies &&
      cookies[config.sessionCookie] &&
      cookies[config.sessionCookie].sessionId
    ) {
      headers.sessionId = cookies[config.sessionCookie].sessionId;
    }
    return await axios.post(url, formData, { headers: headers });
  } catch ({ response }) {
    return response;
  }
};

export const apiList = {
  userLogin: "/user/login",
  getUser: "/user/getUser",
  signup: "/user/signup",

  //Packages
  addPackage: "/package/addPackage",
  getPackages: "/package/getPackages",
  deletePackage: "/package/deletePackage",
  getPackage: "/package/getPackage",
  updatePackage: "/package/updatePackage",
  getOurPackages: "/package/getOurPackages",

  //payment gateway
  generateOrder: "/payment/generateOrder",
  addPayment: "/payment/addPayment",

  // //Project Groups
  // getGroups: "/group/getGroups",
  // addGroup: "/group/addGroup",

  // //Projects
  // getProjects: "/project/getProjects",

  // //Domain
  // addDomain: "/domain/addDomain",
  // getAllDomains: "/domain/getAllDomains",
  // addCompeteDomain: "/domain/addCompeteDomain",
  // deleteDomain: "/domain/deleteDomain",
  // getCompeteDomains: "/domain/getCompeteDomains",

  // //Add Projects
  // addProject: "/project/addProject",

  // //Categories
  // getCategories: "/category/getCategories",
  // addCategory: "/category/addCategory",
  // getCategory: "/category/getCategory",
  // updateCategory: "/category/updateCategory",

  // //Keyword
  // addKeyword: "/keyword/addKeyword",
  // getKeywords: "/keyword/getKeywords",
  // renameKeyword: "/keyword/renameKeyword",
  // deleteKeyword: "/keyword/deleteKeyword",

  // //Auto Schedules
  // getAutoSchedules: "/autoSchedule/getAutoSchedules",
  // addAutoSchedule: "/autoSchedule/addAutoSchedule",
  // updateAutoSchedule: "/autoSchedule/updateAutoSchedule",


  //Workspace
  addWorkspace: "/setup/addWorkspace",
  getWorkspaces: "/setup/getWorkspaces",
  getAllWorkspaceAccess: "/setup/getAllWorkspaceAccess",

  //Keyword
  addKeyword: "/setup/addKeyword",

  //Auto Schedules
  addSchedule: "/setup/addSchedule",

  //Domain
  addDomain: "/setup/addDomain",
  getDomains: "/setup/getDomains",

  //Projects
  addProject: "/setup/addProject",
  getProjects: "/setup/getProjects",

  //Locations
  getLocations: "/serpSettings/getLocations",

  //Keyword Analytics
  getKeywordAnalytics: "/analytics/getKeywordAnalytics",

  //Keywords Data
  getAllKeywrodsData: "/analytics/getAllKeywrodsData",

  getAllDomainTracking: "/setup/getAllDomainTracking",

  getAllKeywordTracking: "/setup/getAllKeywordTracking",

  getAllProjectTracking: "/setup/getAllProjectTracking",

  getHtmlFile: "/setup/getHtmlFile",

  getPayments: "/payment/getPayments",

  //Backlinks
  addBacklink: "/package/addBacklink",
  getBacklinks: "/package/getBacklinks",
  getBacklink: "/package/getBacklink",
  updateBacklink: "/package/updateBacklink",
  deleteBacklink: "/package/deleteBacklink",

  //Backlink Details
  addBacklinkDetails:"/package/addBacklinkDetails",

};
