import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography, Paper,useMediaQuery, Card, List, ListItem, ListItemIcon, Avatar, ListItemText, } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSpring, animated } from '@react-spring/web';
import { motion } from "framer-motion";
import icon1 from "../../../../assets/domainrank.png";
import icon2 from "../../../../assets/keyworddensity.png";
import icon3 from "../../../../assets/contentlength.png";
import icon4 from "../../../../assets/titletaglength.png";
import icon5 from "../../../../assets/tagbounceratemetric.png";
import image from "../../../../assets/queenimage.png";
import domainRankImage from "../../../../assets/domainrank-metric-image.png";
import keywordDensityImage from "../../../../assets/keyworddensitymetricimage.png";
import contentLengthImage from "../../../../assets/contentlengthmetricimage.png";
import titleTagImage from "../../../../assets/titletaglengthmetricimage.png";
import tagDescriptionImage from "../../../../assets/tagdescriptionmetricimage.png";
import LanguageIcon from "@mui/icons-material/Language";


// Styling for Paper components
const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: "#f5fafd",
    borderRadius: "10px",
    boxShadow: "none",
    fontWeight: 600,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease', 
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', 
    },
}));

const Offpage = () => {
    // Page Load Fade-In Animation
    const fadeIn = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 200 });
    const [selectedIndex, setSelectedIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const containerRef = useRef(null);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");

  const data = [
    { id: 1, name: "amazon.com", type: "normal" },
    { id: 2, name: "youbuybooks.co.uk", type: "normal" },
    { id: 3, name: "booksstudio.co.uk/catalog", type: "normal" },
    { id: 4, name: "globestones.com", type: "normal" },
    { id: 5, name: "modernreader.com", type: "normal" },
    { id: 6, name: "desicovcerybook.com", type: "normal" },
    { id: 7, name: "bookspace.com", type: "special" },
  ];



  const metrics = [
    {
      title: "Domain Rank",
      value: "45 +5 Higher",
      icon: <img src={icon1} alt="Domain Rank" style={{ width: "24px", height: "24px" }} />,
      color: "#E7F7EC",
      textColor: "#30A14E",
      content: "The overall traffic rank for AkashBooks.com/about us is higher compared to VibhaReads.com.",
      image: domainRankImage,
    },
    {
      title: "Keyword Density",
      value: "92% Excellent",
      icon: <img src={icon2} alt="Keyword Density" style={{ width: "24px", height: "24px" }} />,
      color: "#E7F7EC",
      textColor: "#30A14E",
      content: "Your website’s SEO score is excellent. Keep optimizing your pages to stay ahead of the competition.",
      image: keywordDensityImage,
    },
    {
      title: "Content Length",
      value: "160 words Too short",
      icon: <img src={icon3} alt="Content Length" style={{ width: "24px", height: "24px" }} />,
      color: "#FDECEA",
      textColor: "#D32F2F",
      content: "The word count is too short for impactful SEO. Consider increasing it to 800+ words for better ranking.",
      image: contentLengthImage,
    },
    {
      title: "Title Tag Length",
      value: "28 ch Too short",
      icon: <img src={icon4} alt="Title Tag Length" style={{ width: "24px", height: "24px" }} />,
      color: "#FDECEA",
      textColor: "#D32F2F",
      content: "The meta title length is below the optimal range. Aim for 50–60 characters to improve click-through rates.",
      image: titleTagImage,
    },
    {
      title: "Tag Description Length",
      value: "80% Good",
      icon: <img src={icon5} alt="Tag Description Length" style={{ width: "24px", height: "24px" }} />,
      color: "#E7F7EC",
      textColor: "#30A14E",
      content: "The meta description is well-optimized. Ensure it includes target keywords and remains engaging.",
      image: tagDescriptionImage,
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsAnimating(true);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

    return (
        <animated.div style={fadeIn}> {/* Wrapping in animated.div */}
            <Box
                sx={{
                    padding: "40px 20px",
                    maxWidth: "1200px",
                    margin: "0 auto",
                    width: '100%',
                    textAlign: "center",
                }}
            >
                {/* Title Section */}
                <Typography variant="h4" fontWeight="bold" gutterBottom>
                    Off-Page SEO Techniques
                </Typography>
                <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ marginBottom: "40px", width: '100%', }}
                >
                    Off-page SEO refers to the actions you take outside the boundaries of your website to influence your rankings on search engines. This includes a variety of techniques, such as:
                </Typography>

                {/* Main Grid Section */}
                <Grid container spacing={4} alignItems="center">
                    {/* Left Side: Website Mockup */}
                    <Grid item xs={12} sm={4}>
                        <Card
                            ref={containerRef}
                            sx={{
                                borderRadius: 4,
                                overflow: "hidden",
                                boxShadow: "0 4px 20px rgba(255, 72, 0, 0.1)",
                                height: isMobile ? "auto" : 415,
                            }}
                        >
                            <List sx={{ position: "relative", height: isMobile ? "auto" : 415 }}>
                                {data.map((item, index) => {
                                    const isLastItem = index === data.length - 1;

                                    return (
                                        <motion.div
                                            key={item.id}
                                            initial={isLastItem && isAnimating ? { opacity: 1, translateY: 0 } : {}}
                                            animate={
                                                isAnimating
                                                    ? isLastItem
                                                        ? { translateY: -index * 60 }
                                                        : { translateY: 60 }
                                                    : {}
                                            }
                                            transition={{
                                                duration: isLastItem ? 2 : 1.5,
                                                ease: "easeInOut",
                                            }}
                                            style={{
                                                position: isLastItem && isAnimating ? "absolute" : "relative",
                                                width: "100%",
                                                zIndex: isLastItem && isAnimating ? 1 : "auto",
                                            }}
                                        >
                                            <ListItem
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    backgroundColor: item.type === "special" ? "#E8F9E8" : "white",
                                                    borderBottom: index < data.length - 1 ? "1px solid #eee" : "none",
                                                    padding: "12px 16px",
                                                    mt: 0.20,
                                                    transition: "background-color 0.3s ease",
                                                    "&:hover": {
                                                        backgroundColor: "#f5f5f5",
                                                    },
                                                }}
                                            >
                                                <ListItemIcon sx={{ minWidth: 36 }}>
                                                    <Avatar
                                                        sx={{
                                                            bgcolor: item.type === "special" ? "#00C853" : "gray",
                                                            width: 32,
                                                            height: 32,
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        {item.type === "special" ? "★" : <LanguageIcon />}
                                                    </Avatar>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item.name}
                                                    primaryTypographyProps={{
                                                        sx: {
                                                            fontSize: item.type === "special" ? "16px" : "13px",
                                                            fontWeight: item.type === "special" ? "bold" : "bold",
                                                            color: item.type === "special" ? "#00C853" : "#333",
                                                        },
                                                    }}
                                                />

                                                {/* Render Number "1" and Icon on the Right ONLY for the Moving Content */}
                                                {isAnimating && isLastItem && (
                                                    <Box
                                                        sx={{
                                                            position: "absolute",
                                                            right: 16,
                                                            top: "50%",
                                                            transform: "translateY(-50%)",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        <Box sx={{ fontSize: "20px", fontWeight: "bold", color: "#00C853", marginRight: 8 }}>
                                                            1st
                                                        </Box>
                                                        {item.type === "special" ? (
                                                            <img src={image} alt="image" style={{ width: "22%", height: "20%" }} />
                                                        ) : (
                                                            <LanguageIcon sx={{ fontSize: 24, color: "#333" }} />
                                                        )}
                                                    </Box>
                                                )}
                                            </ListItem>
                                        </motion.div>
                                    );
                                })}
                            </List>
                        </Card>
                    </Grid>

                    {/* Right Side: SEO Techniques */}
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                            {/* List of SEO techniques with hover effect */}
                            {[
                                "Link Building", "Content Marketing", "Guest Posting",
                                "Competitor Links", "Social Media", "Influencer Marketing",
                                "Brand Mentions", "Online Reviews", "Local SEO", "Digital PR"
                            ].map((text, index) => (
                                <Grid item xs={6} md={4} key={index}>
                                    <StyledPaper>{text}</StyledPaper>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </animated.div>
    );
};

export default Offpage;
