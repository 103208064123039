import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, Box } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Form = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        lookingFor: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const name = formData.name.trim();
        const phone = formData.phone.trim();
        const lookingFor = formData.lookingFor.trim();

        if (!name || !phone || !lookingFor) {
            alert('Please fill all fields.');
            return;
        }

        const phonePattern = /^[0-9]{10}$/;

        if (!phonePattern.test(phone)) {
            alert('Please enter a valid phone number.');
            return;
        }

        alert('Thank you! We will contact you soon!');
        setFormData({ name: '', phone: '', lookingFor: '' });
    };

    return (
        <Box sx={{
            flexGrow: 1,
            padding: { xs: 2, md: 5 },
            backgroundImage: "linear-gradient(90deg,rgb(146, 178, 205), transparent)",
        }}>
            <Grid container spacing={2}>
                {/* Left side content */}
                <Grid item xs={12} md={8}>
                    <Typography variant="h1" sx={{ fontSize: { xs: '1.28rem', md: '2rem' }, width: '100%', fontWeight: 'bold',color:'black' }}>
                        Get Proven Results with a Leading <br />
                        <strong>SEO Agency in Bangalore</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: 2, fontSize: '1rem', width: '90%',color:'black' }}>
                        Are you prepared to establish dominance on Google and elevate your business to the next level? Let’s join forces to ensure your business becomes the top choice for customers.
                    </Typography>
                    <Grid container spacing={2} sx={{ marginTop: 3 }}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" alignItems="center">
                                <CheckCircle sx={{ color: '#e6f7ff', marginRight: 1 }} />
                                <Typography variant="body1" sx={{color:'black'}}>Detailed SEO Audits</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" alignItems="center">
                                <CheckCircle sx={{ color: '#e6f7ff', marginRight: 1 }} />
                                <Typography variant="body1" sx={{color:'black'}}>Result-Oriented Guest Posting</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" alignItems="center">
                                <CheckCircle sx={{ color: '#e6f7ff', marginRight: 1 }} />
                                <Typography variant="body1" sx={{color:'black'}}>Customized SEO Content</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" alignItems="center">
                                <CheckCircle sx={{ color: '#e6f7ff', marginRight: 1 }} />
                                <Typography variant="body1" sx={{color:'black'}}>Consistent ROI Tracking</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" alignItems="center">
                                <CheckCircle sx={{ color: '#e6f7ff', marginRight: 1 }} />
                                <Typography variant="body1" sx={{color:'black'}}>Revenue-Drive SEO Strategy</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" alignItems="center">
                                <CheckCircle sx={{ color: '#e6f7ff', marginRight: 1 }} />
                                <Typography variant="body1" sx={{color:'black'}}>On-Page to Off-Page SEO & Technical SEO</Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{ marginTop: 5 }}>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: '#e6f7ff',color:'black', borderRadius: 4,fontWeight:'bold' }}
                        >
                            Free Website Audit
                        </Button>
                    </Box>
                </Grid>

                {/* Right side form */}
                <Grid item xs={12} md={4}>
                    <Box sx={{
                        padding: 3,
                        borderRadius: 4,
                        boxShadow: 10,
                        backgroundImage: "linear-gradient(90deg,rgb(200, 212, 223), transparent)",
                        marginTop: { xs: 3, md: 0 }
                    }}>
                        <Typography variant="h4" sx={{ fontSize: '2rem' }}>
                            Fill this form & our <br/> <strong>experts will be in touch!</strong>
                        </Typography>

                        <Box
                            component="form"
                            sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 3 }}
                            onSubmit={handleSubmit}
                        >
                            <TextField
                                required
                                id="name"
                                label="Full Name"
                                variant="outlined"
                                fullWidth
                                value={formData.name}
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                id="Email ID"
                                label="Email ID"
                                variant="outlined"
                                fullWidth
                                value={formData.phone}
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                id="phone"
                                label="Phone"
                                variant="outlined"
                                fullWidth
                                value={formData.lookingFor}
                                onChange={handleChange}
                            />

                            
                            <Button variant="contained" type="submit" fullWidth sx={{ borderRadius: 4,backgroundColor:'#93b3ce',fontWeight:'bold' }}>
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Form;
