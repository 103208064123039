import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import seoImage from '../../../../assets/seo_services_icon-1.png';
import experienceImage from '../../../../assets/seo_services_icon-2.png';
import clientsImage from '../../../../assets/seo_services_icon-3.png';
import keywordsImage from '../../../../assets/seo_services_icon-4.png';
import leadsImage from '../../../../assets/seo_services_icon-5.png';

const stats = [
    {
        image: seoImage,
        number: "50+",
        label: "SEO Experts",
    },
    {
        image: experienceImage,
        number: "11",
        label: "Years of Industry Experience",
    },
    {
        image: clientsImage,
        number: "500+",
        label: "Clients",
    },
    {
        image: keywordsImage,
        number: "20000+",
        label: "Keywords ranked on 1st Page",
    },
    {
        image: leadsImage,
        number: "200000+",
        label: "Quality Leads Delivered",
    },
];

const CustomCard = ({ image, number, label }) => (
    <Box
        sx={{
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            borderRadius: 4,
            textAlign: "center",
            backgroundImage: "linear-gradient(to top right, #E8F4FD 0%, transparent, transparent)",
            padding: "30px",
            transition: "transform 0.3s",
            '&:hover': {
                transform: "scale(1.05)",
            },
        }}
    >
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 60,
                height: 60,
                borderRadius: "50%",
                // backgroundColor: "#f0f0f0", 
                // mx: 1,
                ml: -1,
                mb: 2,
                overflow: "hidden",
            }}
        >
            <img
                src={image}
                alt={label}
                style={{
                    width: "80%",
                    height: "80%",
                    objectFit: "cover",
                }}
            />
        </Box>
        <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: "#000", textAlign: 'left' }}
        >
            {number}
        </Typography>
        <Typography
            color="textSecondary"
            fontSize="14px"
            sx={{ whiteSpace: "pre-line", textAlign: 'left' }}
        >
            {label}
        </Typography>
    </Box>
);

const Accelerate = () => {
    return (
        <Box
            sx={{
                p: 4,
                backgroundImage: "linear-gradient(to top right, #E8F4FD 20%, transparent, transparent)",
                textAlign: "center",
            }}
        >
            {/* Header */}
            <Typography
                variant="h4"
                fontWeight="bold"
                mb={4}
                sx={{ color: "#333", lineHeight: 1.4 }}
            >
                Accelerate Your Online Visibility
                <br />
                with Our SEO Expertise
            </Typography>

            {/* Stats Cards */}
            <Grid container spacing={3} justifyContent="center">
                {stats.map((stat, index) => (
                    <Grid item xs={12} sm={6} md={2.4} key={index}>
                        <CustomCard
                            image={stat.image}
                            number={stat.number}
                            label={stat.label}
                        />
                    </Grid>
                ))}
            </Grid>

            {/* Footer Text */}
            <Typography
                mt={5}
                mb={4}
                color="textSecondary"
                fontSize="16px"
                sx={{ lineHeight: 1.6, width: '100%', margin: "0 auto", paddingTop: 7 }}
            >
                As one of the top SEO companies in Bangalore, we understand the challenges that come with digital marketing.
                Our team of skilled SEO professionals manages the complete SEO journey, including rigorous planning, execution,
                and producing significant results such as enhanced keyword ranks, more organic traffic, and increased lead
                generation and sales.
            </Typography>

            {/* Read More Button */}
            <Button
                variant="contained"
                sx={{
                    mt: 2,
                    background: "linear-gradient(to right, #2197b7, #259ba0)",
                    borderRadius: 5,
                    px: 4,
                    py: 1,
                    fontWeight: "bold",
                    color: "white",
                }}
            >
                Read More
            </Button>
        </Box>
    );
};

export default Accelerate;
