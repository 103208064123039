import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import icon1 from '../../../../assets/industries_icon-1.png';
import icon2 from '../../../../assets/industries_icon-2.png';
import icon3 from '../../../../assets/industries_icon-3.png';
import icon4 from '../../../../assets/industries_icon-4.png';
import icon5 from '../../../../assets/industries_icon-5.png';
import icon6 from '../../../../assets/industries_icon-6.png';

const IconImage = styled("img")({
    width: "40px",
    height: "40px",
    marginBottom: "10px",
});

const ServiceCard = ({ title, description, icon }) => (
    <Box
        sx={{
            position: "relative",
            overflow: "hidden",
            transition: "transform 0.3s, box-shadow 0.3s",
            cursor: "pointer",
            height: "350px",
            borderRadius: '10px',
            p: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            "&:hover": {
                // transform: "scale(1.05)",
                backgroundImage: "linear-gradient(180deg, #E9F1F8, transparent)",
                boxShadow: "0 0 10px rgb(0 0 0 / 10%)",
            },
        }}
    >
        <Box p={2}>
            <Box display="flex" flexDirection="column" alignItems="left">
                <IconImage src={icon} alt={`${title} icon`} />
                <Typography variant="h6" fontWeight="bold" gutterBottom sx={{
                    fontWeight: "bold",
                    fontSize: "28px",
                    lineHeight: "1.2",
                    marginBottom: "16px", paddingTop: 2
                }}>
                    {title}
                </Typography>
                <Typography variant="body2" align="left" sx={{
                    width: '350px', fontSize: "16px",
                    lineHeight: "1.6",
                    marginBottom: "24px",
                    color: "#555",
                }}>
                    {description}
                </Typography>
            </Box>
        </Box>
    </Box>
);

const Mastering = () => {
    const services = [
        {
            title: "SEO for Educational Websites",
            description:
                "Our SEO services for educational websites are made to help you reach your target audience and strengthen your online presence by optimizing content, carrying out in-depth keyword research, putting technical SEO strategies into practice, and building high-quality backlinks.",
            icon: icon1,
        },
        {
            title: "SEO for Technology Websites",
            description:
                "Our agency specializes in implementing unique SEO strategies to increase the exposure of your website, attract targeted visitors, and promote your online presence, eventually enhancing the development and success of your business.",
            icon: icon2
        },
        {
            title: "SEO for E-commerce Websites",
            description:
                "We excel at driving organic traffic to e-commerce websites through clever SEO methods, improved product visibility, and conversion funnel optimization to maximize online sales potential.",
            icon: icon3
        },
        {
            title: "SEO for Healthcare Websites",
            description:
                "We specialize in employing healthcare-specific SEO tactics to help our customers increase their online exposure, attract targeted visitors, and achieve targeted objectives such as reservations, patient information, and online reputation management.",
            icon: icon4
        },
        {
            title: "SEO for Real Estate Websites",
            description:
                "We employ our expertise in Real Estate Websites to optimize search engine results, create quality leads, and assure your firm's maximum visibility and success.",
            icon: icon5
        },
        {
            title: "SEO for Travel Websites",
            description:
                "We help customers achieve their organizational goals by driving online bookings, generating quality leads, increasing organic traffic, and attaining higher search engine results, as well as building an influential online presence in the travel sector.",
            icon: icon6
        },
    ];

    return (
        <Box px={2} py={5}>
            <Grid container spacing={3}>
                {services.map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <ServiceCard
                            title={service.title}
                            description={service.description}
                            icon={service.icon}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Mastering;
