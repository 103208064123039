import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    LinearProgress,
} from "@mui/material";
import Tier1image from "../tier1backlinks/PremiumTier1image";
import Tier2image from "./PremiumTier2image";
import Cookies from "js-cookie";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useCookies } from "react-cookie";
import { apiList, invokeApi } from "../../../../../../services/apiServices";
import { config } from "../../../../../../config/config";


// const pricingPlans = [
//     {
//         id: 5,
//         title: "2T Standard",
//         price: "₹ 1,099/-",
//         details: [
// "Backlinks from Blog Sites",
// "Total 1,500 Backlinks",
// "300 Tier 1 Backlinks",
// "1,200 Tier 2 Backlinks",
// "For 1 Website URL",
// "For up to 10 Keywords",
// "100% DoFollow Backlinks",
// "Permanent Backlinks",
// "Free Premium Indexing",
// "Detailed Backlinks Report",
// "White Label Report",
// "Report in 15 Working Days",
//         ],
//         buttonText: "Add to cart",
//         backgroundColor: "#ffffff",
//         textColor: "#000000",
//         buttonColor: "#FF9800",
//     },
//     {
//         id: 6,
//         title: "2T Advanced",
//         price: "₹ 1,999/-",
//         details: [
// "Backlinks from Blog Sites",
// "Total 4,500 Backlinks",
// "900 Tier 1 Backlinks",
// "3,600 Tier 2 Backlinks",
// "For 1 Website URL",
// "For up to 10 Keywords",
// "100% DoFollow Backlinks",
// "Permanent Backlinks",
// "Free Premium Indexing",
// "Detailed Backlinks Report",
// "White Label Report",
// "Report in 15 Working Days",
//         ],
//         buttonText: "Add to cart",
//         backgroundColor: "#ffffff",
//         textColor: "#000000",
//         buttonColor: "#FF9800",
//     },
//     {
//         id: 7,
//         title: "2T Premium",
//         price: "₹ 2,999/-",
//         details: [
// "Backlinks from Blog Sites",
// "Total 9,000 Backlinks",
// "1,800 Tier 1 Backlinks",
// "7,200 Tier 2 Backlinks ",
// "For 1 Website URL",
// "For up to 10 Keywords",
// "100% DoFollow Backlinks",
// "Permanent Backlinks",
// "Free Premium Indexing",
// "Detailed Backlinks Report",
// "White Label Report",
// "Report in 15 Working Days",
//         ],
//         buttonText: "Add to cart",
//         backgroundColor: "#ffffff",
//         textColor: "#000000",
//         buttonColor: "#FF9800",
//     },
//     {
//         id: 8,
//         title: "2T Ultimate",
//         price: "₹ 4,999/-",
//         details: [
// "Backlinks from Blog Sites",
// "Total 18,000 Backlinks",
// "3,600 Tier 1 Backlinks",
// "14,400 Tier 2 Backlinks",
// "For 1 Website URL",
// "For up to 10 Keywords",
// "100% DoFollow Backlinks",
// "Permanent Backlinks",
// "Free Premium Indexing",
// "Detailed Backlinks Report",
// "White Label Report",
// "Report in 15 Working Days",
//         ],
//         buttonText: "Add to cart",
//         backgroundColor: "#ffffff",
//         textColor: "#000000",
//         buttonColor: "#FF9800",
//     },
// ];

const PricingCard = ({ plan, isFirstCard }) => {
    const [inCart, setInCart] = useState(false);

    useEffect(() => {
        const existingCart = Cookies.get("cart") ? JSON.parse(Cookies.get("cart")) : [];
        const isItemInCart = existingCart.some((item) => item.id === plan.id);
        setInCart(isItemInCart);
    }, [plan.id]);

    const handleAddToCart = () => {
        const existingCart = Cookies.get("cart") ? JSON.parse(Cookies.get("cart")) : [];
        if (!existingCart.some((item) => item.id === plan.id)) {
            const updatedCart = [...existingCart, { id: plan.id, name: plan.name, price: plan.price }];
            Cookies.set("cart", JSON.stringify(updatedCart), { expires: 7 });
            setInCart(true);
        }
    };

    const handleRemoveFromCart = () => {
        const existingCart = Cookies.get("cart") ? JSON.parse(Cookies.get("cart")) : [];
        const updatedCart = existingCart.filter((item) => item.id !== plan.id);
        Cookies.set("cart", JSON.stringify(updatedCart), { expires: 7 });
        setInCart(false);
    };

    // Split description into lines
    const descriptionItems = plan.description.split(",").map(item => item.trim());

    return (
        <Card
            sx={{
                backgroundColor: isFirstCard ? '#002966' : '#ffffff',
                color: isFirstCard ? '#ffffff' : '#000000',
                height: "auto",
                borderRadius: "0px",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "20px",
            }}
        >
            <CardContent>
                <Typography variant="h6" fontWeight="bold">
                    {plan.name}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '16px',
                        textDecoration: 'line-through',
                        textDecorationStyle: 'solid',
                        textDecorationColor: 'red',
                        textDecorationThickness: '2px',
                        fontWeight: 600,
                    }}
                >
                    ₹{plan.scratchPrice}
                </Typography>
                <Typography variant="h4" fontWeight="bold">
                    ₹{plan.price}
                </Typography>
                <Box sx={{ paddingTop: "10px" }}>
                    {inCart ? (
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "red",
                                color: "#ffffff",
                                width: "100%",
                                padding: "10px",
                                textTransform: "none",
                                fontWeight: "bold",
                            }}
                            onClick={handleRemoveFromCart}
                        >
                            Remove from Cart
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: isFirstCard ? '#ff9800' : '#005eb0',
                                color: "#ffffff",
                                width: "100%",
                                padding: "10px",
                                textTransform: "none",
                                fontWeight: "bold",
                            }}
                            onClick={handleAddToCart}
                        >
                            Add to Cart <ShoppingCartOutlinedIcon />
                        </Button>
                    )}
                </Box>
            </CardContent>
            <CardContent>
                {descriptionItems.map((item, index) => (
                    <Typography
                        key={index}
                        sx={{ fontSize: "14px", lineHeight: "20px", marginBottom: "5px" }}
                    >
                        - {item}
                    </Typography>
                ))}
            </CardContent>
        </Card>
    );
};

const PremiumBacklinksPricing2 = () => {
    const [cookies] = useCookies();
    const [pricingPlans, setPricingPlans] = useState([]);

    const fetchBackLinkData = async () => {
        const params = {};
        try {
            const response = await invokeApi(
                config.getMyCollege + apiList.getBacklinks,
                params,
                cookies
            );
            if (response?.status === 200) {
                const sortedPlans = response.data.backlinks
                    .filter((item) => item.type === "Tier-2")
                    .sort((a, b) => b.price + a.price); // Sort in descending order by price
                setPricingPlans(sortedPlans);
            } else {
                console.error("Failed to fetch data:", response);
            }
        } catch (error) {
            console.error("Error during data fetch:", error);
        }
    };

    useEffect(() => {
        fetchBackLinkData();
    }, []);

    return (
        <Box sx={{ background: "linear-gradient(135deg, #002966 30%, #005AE2 90%)", padding: "40px 0" }}>
            <Tier2image />
            <Grid container justifyContent="center" alignItems="stretch">
                {pricingPlans.map((plan, index) => (
                    <Grid item xs={12} sm={6} md={2.4} key={plan.id}>
                        <PricingCard plan={plan} isFirstCard={index === 0} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default PremiumBacklinksPricing2;
