import React from "react";
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSpring, animated } from 'react-spring'; // Animation library

const FAQ = () => {
    const faqs = [
        {
            question:
                "How long does it usually take the DashClicks team to build out and launch campaigns?",
            answer:
                "team takes 5-7 business days to build out and launch campaigns once all required information is received.",
        },
        {
            question:
                "How does the onboarding process work after I complete my Facebook Ads purchase?",
            answer:
                "After purchase, you will receive an onboarding email to guide you through the process. Our team will also contact you to collect necessary details.",
        },
        {
            question: "Can you create new campaigns, ad sets, and ads?",
            answer:
                "Yes, our team is equipped to create new campaigns, ad sets, and ads based on your business requirements.",
        },
        {
            question: "What happens when/if the service is cancelled?",
            answer:
                "If the service is cancelled, your campaigns will remain active unless you decide to pause or deactivate them.",
        },
        {
            question: "Does the setup fee include a Funnel?",
            answer: "No, the setup fee is separate from any Funnel services.",
        },
        {
            question: "How long does it take for revisions to be applied?",
            answer:
                "Revisions typically take 2-3 business days, depending on the complexity of the request.",
        },
        {
            question:
                "How often are changes/optimizations performed on active campaigns?",
            answer:
                "Our team regularly monitors and performs optimizations weekly to ensure optimal performance.",
        },
        {
            question:
                "How do I provide you with access to my client’s ad account?",
            answer:
                "You can provide access by sharing your client’s ad account ID. Our team will guide you through the process.",
        },
        {
            question:
                "Will I have access to the ad accounts while you work on them?",
            answer:
                "Yes, you will have full access to the ad accounts and can view the progress anytime.",
        },
        {
            question:
                "What is white label Facebook Ads management and how does it work?",
            answer:
                "White label Facebook Ads management allows agencies to outsource their client’s campaign management while maintaining their branding.",
        },
        {
            question:
                "Why should I outsource white label Facebook Ads services?",
            answer:
                "Outsourcing saves time, ensures expert management, and allows you to focus on growing your business.",
        },
        {
            question:
                "Why use white label Facebook Ads management service?",
            answer:
                "It provides access to experienced professionals who can manage your campaigns efficiently under your brand name.",
        },
    ];

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Array to track the expanded state of each accordion
    const [expanded, setExpanded] = React.useState(Array(faqs.length).fill(false));

    // Function to toggle the expanded state for individual accordion
    const handleChange = (index) => {
        const newExpanded = [...expanded];
        newExpanded[index] = !newExpanded[index]; // Toggle the selected accordion
        setExpanded(newExpanded);
    };

    // Animation logic for expanding accordion
    const springStyle = useSpring({
        to: { opacity: 1, transform: 'scale(1)' },
        from: { opacity: 0, transform: 'scale(0.9)' },
        config: { tension: 170, friction: 26 }
    });

    return (
        <Grid
            sx={{
                p: 3,
                backgroundColor: "#f8fbff",
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#4a4a4a",
                    mb: 3,
                }}
            >
                You've got questions, we've got answers.
            </Typography>
            <Grid container spacing={3}>
                {/* Left Column Accordion */}
                <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {faqs.slice(0, Math.ceil(faqs.length / 2)).map((faq, index) => (
                            <Accordion
                                sx={{
                                    boxShadow: '0 4px 43px #0000001a',
                                    transition: 'all 0.3s ease-in-out',
                                    // borderRadius:3
                                }}
                                expanded={expanded[index]}
                                onChange={() => handleChange(index)}
                                key={index}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`faq-content-${index}`}
                                    id={`faq-header-${index}`}
                                >
                                    <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                                        {faq.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <animated.div style={springStyle}>
                                        <Typography>{faq.answer}</Typography>
                                    </animated.div>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                </Grid>

                {/* Right Column Accordion */}
                <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {faqs.slice(Math.ceil(faqs.length / 2), faqs.length).map((faq, index) => (
                            <Accordion
                                sx={{
                                    boxShadow: '0 4px 43px #0000001a',
                                    transition: 'all 0.3s ease-in-out',
                                }}
                                expanded={expanded[index + Math.ceil(faqs.length / 2)]}
                                onChange={() => handleChange(index + Math.ceil(faqs.length / 2))}
                                key={index + Math.ceil(faqs.length / 2)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`faq-content-${index}`}
                                    id={`faq-header-${index}`}
                                >
                                    <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                                        {faq.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <animated.div style={springStyle}>
                                        <Typography>{faq.answer}</Typography>
                                    </animated.div>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FAQ;