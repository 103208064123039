import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FaLightbulb, FaSearch, FaCogs, FaFileAlt, FaDesktop, FaArrowUp, FaChartLine, FaLink } from "react-icons/fa";
import { Box, Typography } from "@mui/material";

const steps = [
  { id: 1, icon: <FaLightbulb />, title: "Understanding Your Business" },
  { id: 2, icon: <FaSearch />, title: "Researching Keywords" },
  { id: 3, icon: <FaCogs />, title: "Optimizing Technical Aspects" },
  { id: 4, icon: <FaFileAlt />, title: "Enhancing On-Page Elements" },
  { id: 5, icon: <FaDesktop />, title: "Optimizing Content" },
  { id: 6, icon: <FaDesktop />, title: "Improving UI/UX" },
  { id: 7, icon: <FaArrowUp />, title: "Boosting Conversion Rates" },
  { id: 8, icon: <FaChartLine />, title: "Monitoring & Reporting" },
  { id: 9, icon: <FaLink />, title: "Managing Backlinks" },
];

const SeoProcess = () => {
  const { ref, inView } = useInView({ threshold: 0.2, triggerOnce: true });

  return (
    <Box ref={ref} sx={{ margin: "20px", textAlign: "center",mb:8 }}>
      <Typography style={{ textAlign: "center", margin: "20px 0", fontSize: "24px", fontWeight: "bold" }}>
        Our SEO Process
      </Typography>
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          gap: "30px",
        }}
      >
        {steps.map((step, index) => (
          <motion.div
            key={step.id}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "160px",
              padding: "20px",
            //   background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
              borderRadius: "15px",
              boxShadow: "6px 6px 12px #d1d1d1, -6px -6px 12px #ffffff",
              position: "relative",
              cursor: "pointer",
            }}
            initial={{ opacity: 0, y: 30 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: index * 0.2, type: "spring", stiffness: 50 }}
            whileHover={{
              scale: 1.1,
              rotate: 2,
              transition: { type: "spring", stiffness: 300 },
            }}
          >
            <Box
              style={{
                position: "absolute",
                top: "-10px",
                left: "-10px",
                background: "#2197d0",
                color: "#fff",
                fontSize: "12px",
                padding: "5px 10px",
                borderRadius: "20px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              STEP {step.id}
            </Box>
            <Box
              style={{
                fontSize: "40px",
                marginBottom: "10px",
                color: "#2197d0",
              }}
            >
              {step.icon}
            </Box>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              {step.title}
            </div>
          </motion.div>
        ))}
      </Box>
    </Box>
  );
};

export default SeoProcess;
