import React, { useEffect, useRef } from "react";
import { Box, Button, Typography, AppBar, Toolbar, Container } from "@mui/material";
import image from '../../../../../assets/imagebanner1.webp'

function FaceBookInsta() {
    const imgRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (e) => {
            const rect = imgRef.current.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            const centerX = rect.width / 2;
            const centerY = rect.height / 2;

            const rotateX = ((y - centerY) / centerY) * 13.44; // Adjust X-axis rotation
            const rotateY = ((x - centerX) / centerX) * -10; // Adjust Y-axis rotation

            imgRef.current.style.transform = `translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
        };

        const handleMouseLeave = () => {
            if (imgRef.current) {
                imgRef.current.style.transform =
                    "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)";
            }
        };

        const handleScroll = () => {
            if (imgRef.current) {
                const rect = imgRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;

                // If the image is outside of the visible viewport, reset tilt
                if (rect.top < 0 || rect.bottom > windowHeight) {
                    imgRef.current.style.transform =
                        "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)";
                }
            }
        };

        const imageElement = imgRef.current;
        imageElement.addEventListener("mousemove", handleMouseMove);
        imageElement.addEventListener("mouseleave", handleMouseLeave);
        window.addEventListener("scroll", handleScroll);

        return () => {
            imageElement.removeEventListener("mousemove", handleMouseMove);
            imageElement.removeEventListener("mouseleave", handleMouseLeave);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <Box sx={{ background: "linear-gradient(to bottom, #f7f9fc, #d1d9e6)", minHeight: "100vh" }}>
            {/* Header */}

            {/* Hero Section */}
            <Container maxWidth="lg" sx={{ textAlign: "center", pt: 20 }}>
                <Typography variant="h3" sx={{ fontWeight: "bold", color: "#000" }}>
                    White Label Facebook Ads
                </Typography>
                <Typography sx={{ mt: 2, color: "#555", fontSize: "1.2rem" }}>
                    Unlock your agency's potential for success with DashClicks. Enjoy remarkable growth and increased
                    profits through professional white label Facebook Ads services.
                </Typography>
                <Box sx={{ mt: 4, display: "flex", justifyContent: "center", gap: 2 }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#007BFF",
                            color: "#fff",
                            textTransform: "none",
                            px: 4,
                            py: 1.5,
                            fontWeight: "bold",
                            borderRadius: 2,
                        }}
                    >
                        Try it Free
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            textTransform: "none",
                            px: 4,
                            py: 1.5,
                            fontWeight: "bold",
                            borderRadius: 2,
                            borderColor: "#007BFF",
                            color: "#007BFF",
                        }}
                    >
                        Get a Demo
                    </Button>
                </Box>
            </Container>

            {/* Image Section */}
            <Container maxWidth="lg" sx={{ mt: 6 }}>
                <Box
                    className="fulfillment-hero-img-inner"
                    sx={{
                        // background:"tra",
                        perspective: "1000px",
                        transformStyle: "preserve-3d",
                        overflow: "hidden",
                    }}
                >
                    <Box
                        component="img"
                        ref={imgRef}
                        src={image}
                        alt="DashClicks Fulfillment Center"
                        sx={{
                            width: "100%",
                            borderRadius: 4,
                            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.2s ease-out",
                            willChange: "transform",
                        }}
                    />
                </Box>
            </Container>
        </Box>
    );
}

export default FaceBookInsta;
