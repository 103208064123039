import { Grid } from '@mui/material'
import React from 'react'
import Header from '../../../../common/home/Header'
import Footer from '../../../../common/Footer'

const FaceBookAds = () => {
  return (
    <>
    <Header/>
      <Grid>
        FaceBookAds
      </Grid>
      <Footer/>
    </>
  )
}

export default FaceBookAds