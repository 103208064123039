import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Dialog,
    InputLabel,
    MenuItem,
    styled,
    TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import xlfiles from "../../../../assets/7122_21060.xlsx";
import useFetch from "../../useFetch";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { config } from "../../../../config/config";
import { useCookies } from "react-cookie";
import { AnimatePresence, motion } from 'framer-motion';

const AnimatedTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
        borderRadius: "5px",
        "& fieldset": {
            borderColor: "#9e9e9e",
        },
        "&:hover fieldset": {
            borderColor: "#ed6c02",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#ed6c02",
            transition: "border-color 0.3s ease-in-out",
        },
    },
    "& .MuiInputLabel-root": {
        color: "#9e9e9e",
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#ed6c02",
    },
}));

const OrderDetails = () => {
    const { name, roles, email } = useFetch();
    console.log("Email Name==>", email)
    const navigate = useNavigate();
    const location = useLocation();
    const { orderIdDetail } = location.state || {};
    console.log("OrderDetail==>", orderIdDetail);

    const [cookies] = useCookies();
    const userId = cookies[config.cookieName]?.loginUserId;
    const [orderRowData, setOrderRowData] = useState([]);

    const [open, setOpen] = useState(false);
    const [webUrl, setWebUrl] = useState("");
    const [keywordsCount, setKeywordsCount] = useState(1);
    const [keywordsString, setKeywordsString] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);
    const [errors, setErrors] = useState({ webUrl: "", keywords: "", });


    // Handle updating the number of text fields dynamically
    const handleKeywordsCountChange = (e) => {
        const count = parseInt(e.target.value, 10);
        setKeywordsCount(count);

        // Adjust the comma-separated keywords string
        const keywordsArray = keywordsString.split(",").slice(0, count); // Keep only required entries
        setKeywordsString(keywordsArray.join(",")); // Update state with trimmed entries
    };

    // Handle individual keyword input
    const handleKeywordChange = (index, value) => {
        const keywordsArray = keywordsString.split(","); // Convert string to array
        keywordsArray[index] = value || ""; // Update the specific index
        setKeywordsString(keywordsArray.join(",")); // Convert back to a comma-separated string
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleNavigate = () => {
        if (validateFields()) {
            // setIsAddSection(true);
            // navigate("/payment-backlinks");
        }
    }

    const validateFields = () => {
        let isValid = true;
        const newErrors = { webUrl: "", keywords: "" };

        if (!webUrl.trim()) {
            newErrors.webUrl = "Website URL is required.";
            isValid = false;
        }

        const firstKeyword = keywordsString.split(",")[0]?.trim();
        if (!keywordsString.trim()) {
            newErrors.keywords = "At least one keyword is required.";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    useEffect(() => {
        const handleTableData = async () => {
            const params = {
                createdBy: userId
            };

            try {
                const response = await invokeApi(
                    config.getMyCollege + apiList.getPayments,
                    params,
                    cookies
                );

                if (response?.status === 200) {
                    setOrderRowData(response.data.payment)
                    // alert("Paid Successfully!")
                    // // navigate("/done-payment");
                    // navigate("/my-order");
                } else {
                    console.error("Something went wrong. Please try again later!!");
                    alert("Something went wrong. Please try again later!!");
                }
            } catch (error) {
                console.error("Error during data fetch:", error);
                alert("Something went wrong. Please try again later!!");
            }
        };

        handleTableData();
    }, [userId])

    const calculateReportDate = (createdDate) => {
        // Parse the incoming date string
        const date = new Date(createdDate);

        // Add 30 days to the parsed date
        date.setDate(date.getDate() + 30);

        // Format the date as needed (e.g., YYYY-MM-DD)
        const formattedDate = date.toISOString().split("T")[0]; // Extract only the date part

        return formattedDate;
    };


    const rows = [
        { order: "pay_PCOiNk992vGD1R", date: "Wed Oct-23-2024, 01:01pm", status: "Completed", total: "₹ 30,000/-", color: "#4caf50" },
        { order: "pay_PR2WdcorC8aaoJ", date: "Fri Nov-29-2024, 01:04pm", status: "Processing", total: "₹ 39,997/-", color: "red" },
        // { order: "#19849", date: "May 30, 2024", status: "Completed", total: "₹1,000.00 for 1 item" },
    ];

    const handleDownload = () => {
        const filePath = xlfiles; // Relative path to the public directory file
        const link = document.createElement("a");
        link.href = filePath;
        link.download = "7122_21060.xlsx"; // File name for download
        link.click();
    };

    return (
        <Box sx={{ padding: 10, backgroundColor: "#f8f9fd" }}>
            <TableContainer
                component={Paper}
                sx={{
                    borderRadius: 4,
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                }}
            >
                <Table>
                    {/* Table Header */}
                    <TableHead sx={{ backgroundColor: "#eef2f6" }}>
                        <TableRow>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: "#4b5563",
                                    fontSize: "0.95rem",
                                    borderBottom: "2px solid #e5e7eb",
                                }}
                            >
                                Order
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: "#4b5563",
                                    fontSize: "0.95rem",
                                    borderBottom: "2px solid #e5e7eb",
                                }}
                            >
                                Date
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: "#4b5563",
                                    fontSize: "0.95rem",
                                    borderBottom: "2px solid #e5e7eb",
                                }}
                            >
                                Status
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: "#4b5563",
                                    fontSize: "0.95rem",
                                    borderBottom: "2px solid #e5e7eb",
                                }}
                            >
                                Total
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: "#4b5563",
                                    fontSize: "0.95rem",
                                    borderBottom: "2px solid #e5e7eb",
                                }}
                            >
                                Add Section
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: "#4b5563",
                                    fontSize: "0.95rem",
                                    borderBottom: "2px solid #e5e7eb",
                                }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {/* Table Body */}
                    {/* {email === "snistech2023@gmail.com" && ( */}
                    <TableBody>
                        {orderRowData.map((row, index) => (
                            <>
                                <TableRow
                                    key={index}
                                    sx={{
                                        borderBottom: "2px solid #e5e7eb", // Border between rows
                                        "&:hover": { backgroundColor: "#f1f5f9" }, // Hover effect
                                    }}
                                >
                                    <TableCell>
                                        <Typography fontWeight="bold" color="#374151">
                                            {row.orderId}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ color: "#6b7280" }}>{row.createdDate}</TableCell>
                                    <TableCell>
                                        <Typography sx={{ color: row.updatedDate === "Processing" || row.updatedDate === null ? "red" : "#4caf50", fontWeight: "bold", }}>
                                            {row.updatedDate === null ? "Processing" : row.updatedDate}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ color: "#6b7280" }}>{row.paidAmt}</TableCell>
                                    <TableCell>
                                        {(row.orderId ===orderIdDetail&&( orderIdDetail.id && orderIdDetail.isAddLater)) ? (
                                            <Button
                                                variant='contained'
                                                size='small'
                                                sx={{
                                                    textTransform: 'none'
                                                }}
                                                onClick={handleOpen}
                                            >
                                                Add
                                            </Button>
                                        ) : "-"
                                        }

                                    </TableCell>
                                    <TableCell>

                                        Reports Available on {calculateReportDate(row.createdDate)}

                                        {/* <Button
                                        variant="contained"
                                        onClick={() => navigate("/order-details-section", { state: { row } })}
                                        sx={{
                                            backgroundColor: "#5e2ced",
                                            color: "#fff",
                                            textTransform: "capitalize",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            marginRight: 1,
                                            padding: "5px 15px",
                                            "&:hover": { backgroundColor: "#6b21a8" },


                                        }}
                                    >
                                        View
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleDownload}
                                        sx={{
                                            backgroundColor: "#5e2ced",
                                            color: "#fff",
                                            textTransform: "capitalize",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            padding: "5px 15px",
                                            "&:hover": { backgroundColor: "#6b21a8" },
                                        }}
                                    >
                                        Download Report
                                    </Button> */}
                                    </TableCell>
                                </TableRow>


                            </>
                        ))}
                    </TableBody>
                    {/* )} */}

                </Table>
            </TableContainer>
            <Dialog open={open} maxWidth="sm" fullWidth sx={{ borderRadius: "8px", boxShadow: 3, scrollbarWidth: "thin" }}>
                <Box
                    sx={{
                        maxWidth: "600px",
                        bgcolor: "background.paper",
                        p: 3,
                        borderRadius: 2,
                        boxShadow: 3,
                    }}
                >
                    <AnimatePresence mode="wait">
                        <motion.div
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -30 }}
                            transition={{ duration: 0.2, ease: "easeInOut" }}
                        >
                            <Typography
                                gutterBottom
                                sx={{
                                    color: "#FF8C42",
                                    fontSize: "23px",
                                    fontWeight: "bold",
                                    mb: 3,
                                    textAlign: "center",
                                }}
                            >
                                Enter Details Below
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                                    Website URL / Page URL*
                                </InputLabel>
                                <AnimatedTextField
                                    size="small"
                                    label="Name"
                                    name="workspaceName"
                                    value={webUrl}
                                    onChange={(e) => { setWebUrl(e.target.value) }}
                                    error={!!errors.webUrl}
                                    helperText={errors.webUrl}
                                    fullWidth
                                />
                            </Box>

                            {/* Select Keywords Count */}
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 3 }}>
                                <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                                    Total Number of Keywords?*
                                </InputLabel>
                                <AnimatedTextField
                                    select
                                    size="small"
                                    value={keywordsCount}
                                    onChange={handleKeywordsCountChange}
                                    fullWidth
                                >
                                    {Array.from({ length: 10 }, (_, index) => (
                                        <MenuItem key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </MenuItem>
                                    ))}
                                </AnimatedTextField>
                            </Box>

                            {/* Dynamic Keyword Inputs */}
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                                {Array.from({ length: keywordsCount }).map((_, index) => (
                                    <AnimatedTextField
                                        key={index}
                                        size="small"
                                        label={`${index + 1} Keyword ${index === 0 ? "(required)" : "(Optional)"
                                            }`}
                                        required={index === 0}
                                        value={keywordsString.split(",")[index] || ""}
                                        onChange={(e) => handleKeywordChange(index, e.target.value)}
                                        sx={{ flex: "1 1 calc(50% - 10px)" }} // Adjust for two inputs per row
                                        error={index === 0 && !!errors.keywords}
                                        helperText={index === 0 ? errors.keywords : ""}
                                    />
                                ))}
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                                    Image URL (we will added within the blog) (Optional)
                                </InputLabel>
                                <AnimatedTextField
                                    size="small"
                                    label="Image URL"
                                    value={imageUrl}
                                    onChange={(e) => setImageUrl(e.target.value)}
                                    fullWidth
                                />
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                                    YouTube URL (we will added within the blog) (Optional)
                                </InputLabel>
                                <AnimatedTextField
                                    size="small"
                                    label="YouTube URL"
                                    value={videoUrl}
                                    onChange={(e) => setVideoUrl(e.target.value)}
                                    fullWidth
                                />
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                                    Article Upload (Optional - We will use this article for creating the backlinks)
                                </InputLabel>
                                <AnimatedTextField
                                    size="small"
                                    // label="Name"
                                    type='file'
                                    onChange={(e) => setUploadedFile(e.target.files[0])}
                                    fullWidth
                                />
                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
                                <Button variant="contained" color="warning" onClick={() => { handleClose() }}>
                                    Close
                                </Button>

                                <Button variant="contained" color="warning"
                                    //  onClick={() => { handleAddWorkspace(workspaceName) }}
                                    onClick={handleNavigate}
                                >
                                    Add
                                </Button>
                            </Box>
                        </motion.div>
                    </AnimatePresence>
                </Box>
            </Dialog>
        </Box>
    );
};

export default OrderDetails;
