import React from 'react';
import Header from '../../../common/home/Header';
import Footer from '../../../common/Footer';
import { Grid, Box } from '@mui/material';
import SeoBanner from './SeoBanner';
import Accelerate from './Accelerate';
import Mastering from './Mastering';
import Form from './form';
import IndustriesSection from './Industries';
import SeoProcess from './SeoProcess';
import Onpage from './Onpage';
import Offpage from './Offpage';


const SeoServicesHome = () => {
    return (
        <>
            {/* Header with fixed position */}
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 10,
                }}
            >
                <Header />
            </Box>

            {/* Main content container */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // minHeight: '100vh', 
                    // paddingTop: '50px',
                    // paddingBottom: '64px', 
                    backgroundColor: '#ffff',
                }}
            >
                <Grid
                    container
                    direction="column"
                    sx={{
                        backgroundColor: '#ffff',
                        overflowX: 'hidden',
                        margin: 0,
                        mt: 8
                    }}
                >
                    <SeoBanner />
                    <Accelerate />
                    <Mastering />
                    <Form />
                    <SeoProcess />
                    <Onpage />
                    <Offpage/>
                    <IndustriesSection />

                </Grid>
            </Box>

            {/* Footer with fixed position */}

            <Footer />

        </>
    );
};

export default SeoServicesHome;
