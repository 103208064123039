import * as React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Grid } from "@mui/material";
import KeywordHome from "./components/pages/Dashboard/DashboardMainPages/keywordexplore/KeywordHome";
import Wordcounthome from "./components/pages/Dashboard/DashboardMainPages/othertools/wordcount/Wordcounthome";
// import Dashboard from "./components/pages/Dashboard/Dashboard";
import Homescreen from "./components/pages/Home/Homescreen";
import PlagiarismChecker from "./components/pages/Home/tools/PlagiarismChecker";
import ArticleRewriter from "./components/pages/Home/tools/ArticleRewriter";
import GrammarChecker from "./components/pages/Home/tools/GrammarChecker";
import WordCounter from "./components/pages/Home/tools/WordCounter";
import SpellChecker from "./components/pages/Home/tools/SpellChecker";
import ParaphrasingTool from "./components/pages/Home/tools/ParaphrasingTool";
import MD5Generator from "./components/pages/Home/tools/MD5Generator";
import { ThemeProvider } from "./components/common/ThemeContext";
import TermsandCondition from "./components/pages/Home/TermsandCondition";
import PrivacyPolicy from "./components/pages/Home/PrivacyPolicy";
import CancellationRefundPolicy from "./components/pages/Home/CancellationRefundPolicy";
import ShippingDeliveryPolicy from "./components/pages/Home/ShippingDeliveryPolicy";
import Contact from "./components/pages/Home/Contact";
import ReverseTextGenerator from "./components/pages/Home/tools/ReverseTextGenerator";
import SmallTextGenerator from "./components/pages/Home/tools/SmallTextGenerator";
import TextToSpeech from "./components/pages/Home/tools/TextToSpeech";
import TextToImage from "./components/pages/Home/tools/TextToImage";
import Translate from "./components/pages/Home/tools/Translate";
import ImageToTextConvert from "./components/pages/Home/tools/ImageToTextConverter";
import WordCombiner from "./components/pages/Home/tools/WordCombiner";
import Uppertolower from "./components/pages/Home/tools/Uppertolower";
import Login from "./Authentication/Login";
import Register from "./Authentication/Register";
import ProtectedRoute from "./page-restriction/ProtectedRoute";
import Pricingdetailhome from "./components/pages/Home/pricing/pricingdetail/Pricingdetailhome";
import SeoAuditHome from "./components/pages/Home/seooptimizer/SeoAuditHome";
import HomeMore from "./components/pages/Dashboard/DashboardHome/Morepages/HomeMore";
import ProfileHome from "./components/pages/Dashboard/pages/Avathar/Myprofile/ProfileHome";
import PlanBillingHome from "./components/pages/Dashboard/pages/Avathar/PlanBilling/PlanBillingHome";
import RefaralPointHome from "./components/pages/Dashboard/pages/Avathar/Referalpoints/RefaralPointHome";
import ManageUserHome from "./components/pages/Dashboard/pages/Avathar/ManageUsers/ManageUserHome";
import WorkspaceHome from "./components/pages/Dashboard/pages/ContentTool/Workspace/WorkspaceHome";
import ParaphraseHome from "./components/pages/Dashboard/pages/ContentTool/ParagrafhTool/ParaphraseHome";
import AiCheckerHome from "./components/pages/Dashboard/pages/ContentTool/Aichecker/AiCheckerHome";
import AddWebTabHome from './components/pages/Dashboard/pages/ContentTool/AddWebsite/AddWebTabHome';
import HeadlineAnalyzerHome from "./components/pages/Dashboard/pages/ContentTool/headlineAnalyzer/HeadlineAnalyzerHome";
import Packeges from "./components/pages/Dashboard/pages/Settings/Packeges";
import PaymentPage from "./components/pages/Dashboard/DashboardHome/PaymentPage";
import MonthlyBacklinks from "./components/pages/Dashboard/pages/montlybacklinks/MonthlyBacklinks";
import PremiumBacklinksHome from "./components/pages/Dashboard/pages/premium-backlinks/PremiumBacklinksHome";
import WorkspaceMainPage from "./components/pages/Dashboard/DashboardHome/WorkspaceMainPage";
import UserDashboard from "./components/setup/layouts/UserDashboard";
import Projects from "./components/setup/layouts/Projects";
import Domain from "./components/setup/layouts/Domain";
import Keywords from "./components/setup/layouts/Keywords";
import Workspace from "./components/setup/layouts/workspace/Workspace";
import PaymentBacklinks from "./components/pages/Dashboard/DashboardHome/PaymentBacklinks";
import DonePayment from "./components/setup/layouts/donePayment/DonePayment";
import Backlink from "./components/pages/Home/orders/Backlink";
import OrderDetailsSection from "./components/pages/Home/orders/OrderDetailsSection";
import CommonHomePage from "./components/setup/layouts/CommonHomePage";
import Faq from "./components/pages/Home/pricing/pricingdetail/Faq";
import AboutHome from "./components/pages/Home/AboutHome";
import BackLinks from "./components/pages/Dashboard/pages/Settings/BackLinks";
import SeoServicesHome from "./components/pages/Home/seoservices/SeoServicesHome";
import FaceBookAndInstagramAdsHome from "./components/pages/Home/ads/facebookAndInstagramads/FaceBookAndInstagramAdsHome";
import FaceBookAds from "./components/pages/Home/ads/facebookAds/FaceBookAds";
import InstagramAds from "./components/pages/Home/ads/instagramAds/InstagramAds";
import GoogleAds from "./components/pages/Home/ads/googleAds/GoogleAds";
import YouTubeAds from "./components/pages/Home/ads/youtubeAds/YouTubeAds";
import WeAreHiringHome from "./components/pages/Home/weAreHiring/WeAreHiringHome";
import CampaignObjective from "./components/pages/Home/ads/facebookAndInstagramads/CampaignObjective";
import PaymentAds from "./components/pages/Home/ads/facebookAndInstagramads/PaymentAds";


export default function App() {
  return (
    <ThemeProvider>
      <Grid>
        <Router>
          <Routes>
            <Route path="/" element={<Homescreen />} />
            <Route path="/my-order" element={<Backlink />} />
            <Route path="/about-us" element={<AboutHome />} />
            <Route path="/order-details-section" element={<OrderDetailsSection />} />
            <Route path="/common-home" element={
              <ProtectedRoute>
                <CommonHomePage />
              </ProtectedRoute>
            } />


            {/* New Version */}
            <Route
              path="/user-dashboard"
              element={
                <ProtectedRoute>
                  <UserDashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/projects/:id"
              element={
                <ProtectedRoute>
                  <Projects />
                </ProtectedRoute>
              }
            />

            <Route
              path="/domain/:id"
              element={
                <ProtectedRoute>
                  <Domain />
                </ProtectedRoute>
              }
            />

            <Route
              path="/keywords/:id"
              element={
                <ProtectedRoute>
                  <Keywords />
                </ProtectedRoute>
              }
            />

            <Route
              path="/workspace"
              element={
                <ProtectedRoute>
                  <Workspace />
                </ProtectedRoute>
              }
            />





            <Route
              path="/payment-page"
              element={
                // <ProtectedRoute>
                <PaymentPage />
                // </ProtectedRoute>
              }
            />

            <Route
              path="/payment-backlinks"
              element={
                // <ProtectedRoute>
                <PaymentBacklinks />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/payment-ads"
              element={
                // <ProtectedRoute>
                <PaymentAds />
                // </ProtectedRoute>
              }
            />

            <Route
              path="/done-payment"
              element={
                <ProtectedRoute>
                  <DonePayment />
                </ProtectedRoute>
              }
            />

            <Route
              path="/workspace"
              element={
                <ProtectedRoute>
                  <WorkspaceMainPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/keywordHome"
              element={
                <ProtectedRoute>
                  <KeywordHome />
                </ProtectedRoute>
              }
            />
            <Route
              path="/premium-backlinks-home"
              element={
                <ProtectedRoute>
                  <PremiumBacklinksHome />
                </ProtectedRoute>
              }
            />
            <Route
              path="/monthly-backlinks"
              element={
                <MonthlyBacklinks />
              }
            />
            <Route
              path="/wordcounthome"
              element={
                <ProtectedRoute>
                  <Wordcounthome />
                </ProtectedRoute>
              }
            />
            <Route
              path="/packages"
              element={
                <ProtectedRoute>
                  <Packeges />
                </ProtectedRoute>
              }
            />
            <Route
              path="/backlinks"
              element={
                <ProtectedRoute>
                  <BackLinks />
                </ProtectedRoute>
              }
            />
            <Route path="//Pricing-detail" element={<Pricingdetailhome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/:id" element={<Register />} />
            <Route path="/profile" element={<ProfileHome />} />
            <Route path="/plan-billing" element={<PlanBillingHome />} />
            <Route path="/refaral-point" element={<RefaralPointHome />} />
            <Route path="/manage-user" element={<ManageUserHome />} />
            <Route path="/workspace" element={<WorkspaceHome />} />
            <Route path="/paragrafh-tool" element={<ParaphraseHome />} />
            <Route path="/ai-checker" element={<AiCheckerHome />} />
            <Route path="/addweb-tabhome" element={<AddWebTabHome />} />
            <Route path="/headline-analyzer" element={<HeadlineAnalyzerHome />} />




            {/* Home Pages */}
            {/* Tools section */}
            {/* <Route path="/" element={<ToolsGrid />} /> */}
            <Route path="/plagiarism-checker" element={<PlagiarismChecker />} />
            <Route path="/article-rewriter" element={<ArticleRewriter />} />
            <Route path="/Grammar-Checker" element={<GrammarChecker />} />
            <Route path="/word-counter" element={<WordCounter />} />
            <Route path="/spell-checker" element={<SpellChecker />} />
            <Route path="/paraphrasing-tool" element={<ParaphrasingTool />} />
            <Route path="/md5-generator" element={<MD5Generator />} />
            <Route path="/upper-to-lower" element={<Uppertolower />} />
            <Route path="/word-combiner" element={<WordCombiner />} />
            <Route path="/image-to-text" element={<ImageToTextConvert />} />
            <Route path="/translate" element={<Translate />} />
            <Route path="/text-to-image" element={<TextToImage />} />
            <Route path="/text-to-speech" element={<TextToSpeech />} />
            <Route path="/seo-audit" element={<SeoAuditHome />} />
            <Route path="/more" element={<HomeMore />} />
            <Route path="/adsmanager" element={<CampaignObjective />} />
            <Route
              path="/small-text-generator"
              element={<SmallTextGenerator />}
            />
            <Route
              path="/reverse-text-generator"
              element={<ReverseTextGenerator />}
            />

            {/* Footer */}
            <Route path="/faq" element={<Faq />} />
            <Route path="/terms-and-condition" element={<TermsandCondition />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/we-are-hiring" element={<WeAreHiringHome />} />
            <Route
              path="/cancellation-refund-policy"
              element={<CancellationRefundPolicy />}
            />
            <Route
              path="/shipping-delivery-policy"
              element={<ShippingDeliveryPolicy />}
            />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/seo-services-home" element={<SeoServicesHome />} />
            <Route path="/facebook-instagram-ads" element={<FaceBookAndInstagramAdsHome />} />
            <Route path="/facebook-ads" element={<FaceBookAds />} />
            <Route path="/instagram-ads" element={<InstagramAds />} />
            <Route path="/google-ads" element={<GoogleAds />} />
            <Route path="/youtube-ads" element={<YouTubeAds />} />
          </Routes>
        </Router>
      </Grid>
    </ThemeProvider>
  );
}
