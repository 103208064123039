import React from 'react'
import Header from '../../../../common/home/Header'
import Footer from '../../../../common/Footer'
import { Grid } from '@mui/material'
import FaceBookInsta from './FaceBookInsta'
import Banner2 from './Banner2'
import Banner1 from './Banner1'
import FAQ from './Faq'
import FacebookAdvertisingProcess from './FaceBookAdvertisingProcess'
import FeedBack from './FeedBack'
import PricingSection from './PricingSection';

const FaceBookAndInstagramAdsHome = () => {
  return (
    <>
      <Header />
      <Grid>
        <Banner1 />
        <PricingSection/>
        <FacebookAdvertisingProcess/>
        <FaceBookInsta />
        <Banner2 />
        <FeedBack/>
        <FAQ/>
      </Grid>
      <Footer />
    </>
  )
}

export default FaceBookAndInstagramAdsHome;