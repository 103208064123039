import React from 'react'
import BannerSection from './BannerSection'
import Header from '../../../common/home/Header'
import Footer from '../../../common/Footer'

const WeAreHiringHome = () => {
  return (
    <>
      <Header />
      <BannerSection />
      <Footer />
    </>
  )
}

export default WeAreHiringHome