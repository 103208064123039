import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Paper,
  ListItemButton,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";
import LanguageIcon from "@mui/icons-material/Language";
import domainRankImage from "../../../assets/domainrank-metric-image.png";
import keywordDensityImage from "../../../assets/keyworddensitymetricimage.png";
import contentLengthImage from "../../../assets/contentlengthmetricimage.png";
import titleTagImage from "../../../assets/titletaglengthmetricimage.png";
import tagDescriptionImage from "../../../assets/tagdescriptionmetricimage.png";
import icon1 from "../../../assets/domainrank.png";
import icon2 from "../../../assets/keyworddensity.png";
import icon3 from "../../../assets/contentlength.png";
import icon4 from "../../../assets/titletaglength.png";
import icon5 from "../../../assets/tagbounceratemetric.png";
import image from "../../../assets/queenimage.png";

const RankHigher = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const containerRef = useRef(null);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");

  const data = [
    { id: 1, name: "amazon.com", type: "normal" },
    { id: 2, name: "youbuybooks.co.uk", type: "normal" },
    { id: 3, name: "booksstudio.co.uk/catalog", type: "normal" },
    { id: 4, name: "globestones.com", type: "normal" },
    { id: 5, name: "modernreader.com", type: "normal" },
    { id: 6, name: "desicovcerybook.com", type: "normal" },
    { id: 7, name: "bookspace.com", type: "special" },
  ];

  const metrics = [
    {
      title: "Domain Rank",
      value: "45 +5 Higher",
      icon: <img src={icon1} alt="Domain Rank" style={{ width: "24px", height: "24px" }} />,
      color: "#E7F7EC",
      textColor: "#30A14E",
      content: "The overall traffic rank for AkashBooks.com/about us is higher compared to VibhaReads.com.",
      image: domainRankImage,
    },
    {
      title: "Keyword Density",
      value: "92% Excellent",
      icon: <img src={icon2} alt="Keyword Density" style={{ width: "24px", height: "24px" }} />,
      color: "#E7F7EC",
      textColor: "#30A14E",
      content: "Your website’s SEO score is excellent. Keep optimizing your pages to stay ahead of the competition.",
      image: keywordDensityImage,
    },
    {
      title: "Content Length",
      value: "160 words Too short",
      icon: <img src={icon3} alt="Content Length" style={{ width: "24px", height: "24px" }} />,
      color: "#FDECEA",
      textColor: "#D32F2F",
      content: "The word count is too short for impactful SEO. Consider increasing it to 800+ words for better ranking.",
      image: contentLengthImage,
    },
    {
      title: "Title Tag Length",
      value: "28 ch Too short",
      icon: <img src={icon4} alt="Title Tag Length" style={{ width: "24px", height: "24px" }} />,
      color: "#FDECEA",
      textColor: "#D32F2F",
      content: "The meta title length is below the optimal range. Aim for 50–60 characters to improve click-through rates.",
      image: titleTagImage,
    },
    {
      title: "Tag Description Length",
      value: "80% Good",
      icon: <img src={icon5} alt="Tag Description Length" style={{ width: "24px", height: "24px" }} />,
      color: "#E7F7EC",
      textColor: "#30A14E",
      content: "The meta description is well-optimized. Ensure it includes target keywords and remains engaging.",
      image: tagDescriptionImage,
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsAnimating(true);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <Grid container spacing={2} sx={{ p: isMobile ? 2 : 4 }}>
      {/* Left Side */}
      <Grid item xs={12} sm={4}>
        <Card
          ref={containerRef}
          sx={{
            borderRadius: 4,
            overflow: "hidden",
            boxShadow: "0 4px 20px rgba(255, 72, 0, 0.1)",
            height: isMobile ? "auto" : 415,
          }}
        >
          <List sx={{ position: "relative", height: isMobile ? "auto" : 415 }}>
            {data.map((item, index) => {
              const isLastItem = index === data.length - 1;

              return (
                <motion.div
                  key={item.id}
                  initial={isLastItem && isAnimating ? { opacity: 1, translateY: 0 } : {}}
                  animate={
                    isAnimating
                      ? isLastItem
                        ? { translateY: -index * 60 }
                        : { translateY: 60 }
                      : {}
                  }
                  transition={{
                    duration: isLastItem ? 2 : 1.5,
                    ease: "easeInOut",
                  }}
                  style={{
                    position: isLastItem && isAnimating ? "absolute" : "relative",
                    width: "100%",
                    zIndex: isLastItem && isAnimating ? 1 : "auto",
                  }}
                >
                  <ListItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: item.type === "special" ? "#E8F9E8" : "white",
                      borderBottom: index < data.length - 1 ? "1px solid #eee" : "none",
                      padding: "12px 16px",
                      mt: 0.20,
                      transition: "background-color 0.3s ease",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Avatar
                        sx={{
                          bgcolor: item.type === "special" ? "#00C853" : "gray",
                          width: 32,
                          height: 32,
                          fontSize: 16,
                        }}
                      >
                        {item.type === "special" ? "★" : <LanguageIcon />}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{
                        sx: {
                          fontSize: item.type === "special" ? "16px" : "13px",
                          fontWeight: item.type === "special" ? "bold" : "bold",
                          color: item.type === "special" ? "#00C853" : "#333",
                        },
                      }}
                    />

                    {/* Render Number "1" and Icon on the Right ONLY for the Moving Content */}
                    {isAnimating && isLastItem && (
                      <Box
                        sx={{
                          position: "absolute",
                          right: 16,
                          top: "50%",
                          transform: "translateY(-50%)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Box sx={{ fontSize: "20px", fontWeight: "bold", color: "#00C853", marginRight: 8 }}>
                          1st
                        </Box>
                        {item.type === "special" ? (
                          <img src={image} alt="image" style={{ width: "22%", height: "20%" }} />
                        ) : (
                          <LanguageIcon sx={{ fontSize: 24, color: "#333" }} />
                        )}
                      </Box>
                    )}
                  </ListItem>
                </motion.div>
              );
            })}
          </List>
        </Card>
      </Grid>

      {/* Right Side */}
      <Grid item xs={12} sm={8} display="flex" flexDirection={"row"}>
        {/* Left Sidebar */}
        <Grid item xs={6} mr={2}>
          <List sx={{ height: "100%", overflowY: "auto" }}>
            {metrics.map((item, index) => (
              <ListItemButton
                key={index}
                onClick={() => setSelectedIndex(index)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  backgroundColor: selectedIndex === index ? item.color : "white",
                  borderRadius: 2,
                  mb: 1,
                }}
              >
                <Avatar sx={{ backgroundColor: item.color, color: item.textColor }}>
                  {item.icon}
                </Avatar>
                <Box sx={{ boxShadow: "0 4px 20px rgba(255, 255, 255, 0.1)" }}>
                  <Typography variant="body1" fontWeight="bold" style={{ fontWeight: "bolder" }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color={item.textColor}>
                    {item.value}
                  </Typography>
                </Box>
              </ListItemButton>
            ))}
          </List>
        </Grid>

        {/* Right Content */}
        <Grid item xs={6} display="flex" flexDirection="column" justifyContent="space-between">
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              height: "100%",
              display: "flex",
              boxShadow: "0 4px 20px rgba(255, 72, 0, 0.1)",
              flexDirection: "column",
              position: "relative",
              overflowY: "scroll",
            }}
          >
            {/* Top Section with Image */}
            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
              <Box display="flex" justifyContent="flex-start" width="100%">
                <Typography variant="h6" fontWeight="bold" style={{ marginBottom: 10, fontWeight: "bold" }}>
                  {metrics[selectedIndex].title}
                </Typography>
              </Box>
              <img
                src={metrics[selectedIndex].image}
                alt={metrics[selectedIndex].title}
                style={{ width: "50%", height: "auto", borderRadius: 8 }}
              />
            </Box>

            {/* Content Section with Scrolling */}
            <Box
              sx={{
                flex: 1,
                overflowY: "auto", 
              }}
            >
              <Typography variant="body1" color="text.secondary" paragraph>
                {metrics[selectedIndex].content} {metrics[selectedIndex].content} {metrics[selectedIndex].content}
                {/* Add content here up to around 400 words */}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RankHigher;
