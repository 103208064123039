import React, { useEffect } from 'react';
import Tools from './tools/ToolsGrid';
import { Grid } from '@mui/material';
import Footer from '../../common/Footer';
import AiTools from './Aitools/AiTools';
import Relatedtool from './Aitools/Relatedtool';
import Populartool from './tools/Populartool';
import Banner from './tools/Banner';
import HomeBanner from './HomeBanner';
import Organizations from './Organizations';
import WhyChooseUs from './WhyChooseUs';
import Header from '../../common/home/Header';
import RankHigher from './RankHigher';
import About from './About';
import FunFacts from './FunFacts';

const Homescreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: '#fff9f7',
        width: { md: '100vw', xs: '120vw' }, // Set to 100% of viewport width consistently
        overflowX: 'hidden', // Prevent horizontal scrolling
        margin: 0,
      }}
    >
      <Grid item>
        <Header />
      </Grid>
      <Grid item>
        <HomeBanner />
      </Grid>
      <Grid item>
        <About />
      </Grid>
      <Grid item>
        <Organizations />
      </Grid>
      <Grid item>
        <RankHigher />
      </Grid>

      {/* Uncomment and adjust this block if needed */}
      {/* <Grid container spacing={3} sx={{ mt: 3, px: 3 }}>
        <Grid item xs={12} md={9}>
          <Tools />
          <AiTools />
        </Grid>
        <Grid item xs={12} md={3}>
          <Populartool />
          <Relatedtool />
        </Grid>
      </Grid> */}

      <Grid item>
        <WhyChooseUs />
      </Grid>
      <Grid item>
        <FunFacts />
      </Grid>

      <Grid item>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Homescreen;
