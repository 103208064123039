import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import image1 from "../../../../assets/onpageimage1.png";
import image2 from "../../../../assets/onpageimage2.png";
import image3 from "../../../../assets/onpageimage3.png";

const ContentContainer = styled(Box)({
    padding: "20px",
});

const ImageContainer = styled(Box)({
    position: "relative",
    height: "300px",
    borderRadius: "8px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#f5f5f5",
});

const ProgressCircle = ({ value, label, color }) => (
    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <CircularProgress
            variant="determinate"
            value={value}
            size={70}
            thickness={4}
            style={{ color }}
        />
        <Typography variant="h6" mt={1}>
            {value}%
        </Typography>
        <Typography variant="body2" color="textSecondary">
            {label}
        </Typography>
    </Box>
);

const RotatingImages = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const images = [image1, image2, image3];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prev) => (prev + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <ImageContainer>
            <img
                src={images[currentImage]}
                alt={`Image ${currentImage + 1}`}
                style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "8px" }}
            />
        </ImageContainer>
    );
};

const Onpage = () => {
    return (
        <Grid container spacing={4} alignItems="center" px={4}>
            {/* Left Side Content */}
            <Grid item xs={12} md={6}>
                <ContentContainer>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Social Media for
                    </Typography>
                    <Typography
                        variant="h6"
                        gutterBottom
                        fontWeight="bold"
                        sx={{ color: '#2197d0' }}
                    >
                        IP Management Advisory Firm
                    </Typography>
                    <Typography variant="body2" mt={2} mb={3}>
                        We specialize in enhancing your on-page SEO by optimizing meta tags, headings, and content relevance. Our team ensures your website is structured for maximum search engine visibility and user engagement. From keyword research to implementing technical SEO best practices, we take care of every detail to help your site rank higher.
                    </Typography>
                    <Box display="flex" justifyContent="space-between" mt={3} mb={3}>
                        <ProgressCircle
                            value={78}
                            label="Increase in Organic Visitors"
                            color="#ff5722"
                        />
                        <ProgressCircle
                            value={75}
                            label="Increase in Website Authority (DR)"
                            color="#4caf50"
                        />
                        <ProgressCircle
                            value={95}
                            label="Increase in Website Health Score"
                            color="#3f51b5"
                        />
                    </Box>
                    <Box mt={3} display="flex" gap={2}>
                        <Button
                            variant="contained"
                            sx={{ fontWeight: "bold", bgcolor: '#2197d0' }}
                        >
                            Free Website Audit
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ fontWeight: "bold", bgcolor: '#2197d0' }}
                        >
                            Get a Free Quotation
                        </Button>
                    </Box>
                </ContentContainer>
            </Grid>

            {/* Right Side Image Section */}
            <Grid item xs={12} md={6}>
                <RotatingImages />
            </Grid>
        </Grid>
    );
};

export default Onpage;
