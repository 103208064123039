import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import React from 'react';
import img from '../../../assets/about-image.png';
import { Box } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const About = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{ backgroundColor: '#ffff', py: 6 }}>
            <Grid container spacing={4} sx={{ px: { xs: 2, sm: 4, md: 8 } }}>
                {/* Left Image Section */}
                <Grid item md={5} xs={12}>
                    <img
                        src={img}
                        alt="About Us"
                        style={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: '10px',
                        }}
                    />
                </Grid>
                {/* Right Text Section */}
                <Grid
                    item
                    md={7}
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent={isSmallScreen ? 'center' : 'flex-start'}
                >
                    <Grid container spacing={3}>
                        {/* About Section */}
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                gutterBottom
                                sx={{
                                    fontSize: isSmallScreen ? '28px' : isMediumScreen ? '34px' : '40px',
                                    fontWeight: 700,
                                    color: '#FF8C32',
                                    textAlign: isSmallScreen ? 'center' : 'left',
                                }}
                            >
                                About
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#717173',
                                    fontSize: isSmallScreen ? '15px' : '17px',
                                    fontWeight: 300,
                                    lineHeight: '28px',
                                    textAlign: isSmallScreen ? 'center' : 'left',
                                }}
                            >
                                At SEO Mitra, we specialize in empowering businesses with tailored backlink services and advanced keyword tracking solutions. We are your go-to partner for boosting online visibility and driving measurable results.
                            </Typography>
                        </Grid>
                        {/* Who We Are Section */}
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                sx={{
                                    fontSize: '22px',
                                    fontWeight: 600,
                                    color: '#333',
                                }}
                            >
                                Who we are
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#717173',
                                    fontSize: '15px',
                                    fontWeight: 300,
                                    lineHeight: '26px',
                                }}
                            >
                                Founded in 2016 by a team of seasoned SEO experts and digital marketers, SEO Mitra is driven by a passion for helping businesses succeed online through data-driven strategies and cutting-edge SEO tools.
                            </Typography>
                        </Grid>
                        {/* Why SEO Mitra Section */}
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                sx={{
                                    fontSize: '22px',
                                    fontWeight: 700,
                                    color: '#333',
                                }}
                            >
                                Why SEO Mitra?
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#717173',
                                    fontSize: '15px',
                                    fontWeight: 300,
                                    lineHeight: '26px',
                                }}
                            >
                                We enable businesses to unlock their true potential with our expertise in backlink building, keyword tracking, and proven strategies to enhance search engine rankings, website traffic, and conversion rates.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Features Section */}
            <Grid
                container
                spacing={4}
                sx={{
                    mt: 4,
                    backgroundColor: '#f9f9f9',
                    py: isSmallScreen ? 2 : 3,
                    px: isSmallScreen ? 2 : 4,
                    borderRadius: '10px',
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                }}
                justifyContent="space-between"
                alignItems="center"
            >
                {[
                    { title: 'Improve your rankings', desc: 'Boost your positions in the SERP' },
                    { title: 'Track your performance', desc: 'Daily reports by e-mail' },
                    { title: 'Outrank competitors', desc: 'Know their rankings and beat them' },
                    { title: 'Snowball organic volumes', desc: 'Discover new important keywords' },
                ].map((item, index) => (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 2,
                            textAlign: 'left',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                transition: 'all 0.3s ease',
                            },
                        }}
                    >
                        <CheckCircleIcon
                            sx={{
                                fontSize: { xs: '30px', md: '30px' },
                                color: '#FF8C32',
                                mr: 2,
                            }}
                        />
                        <Box>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                {item.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ fontSize: '14px' }}>
                                {item.desc}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default About;