import React from 'react'
import Header from '../../../../common/home/Header'
import Footer from '../../../../common/Footer'
import { Grid } from '@mui/material'

const YouTubeAds = () => {
  return (
    <>
      <Header />
      <Grid>
        FaceBookAndInstagramAds
      </Grid>
      <Footer />
    </>

  )
}

export default YouTubeAds