import React from 'react'
import Header from '../../../../common/home/Header'
import Footer from '../../../../common/Footer'
import { Grid } from '@mui/material'

const GoogleAds = () => {
  return (
    <>
      <Header />
      <Grid>
        GoogleAds
      </Grid>
      <Footer />
    </>
  )
}

export default GoogleAds