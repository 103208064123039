import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { Box, Grid, Typography } from "@mui/material";

const FunFacts = ({
    colleges = 1000,
    students = 800,
    advisorRating = 640,
}) => {
    const collegeSpring = useSpring({
        number: colleges,
        from: { number: 0 },
        delay: 200,
        config: { duration: 2000 },
    });
    const studentSpring = useSpring({
        number: students,
        from: { number: 0 },
        delay: 400,
        config: { duration: 2000 },
    });
    const advisorSpring = useSpring({
        number: advisorRating,
        from: { number: 0 },
        delay: 600,
        config: { duration: 2000 },
    });

    return (
        <Box sx={{ backgroundColor: "#f7f7f7", py: 6 }}>
            <Typography
                variant="h4"
                sx={{
                    fontWeight: "bold",
                    mb: 4,
                    color: "#212121",
                    textAlign: "center",
                    fontSize: { xs: "28px", md: "36px" },
                }}
            >
                Explore Our Achievements
            </Typography>
            <Grid container justifyContent="center" spacing={4} sx={{ px: 3 }}>
                {[
                    {
                        spring: collegeSpring,
                        title: "Registered Colleges",
                        description:
                            "Over a decade of expertise in curating the finest digital marketing strategies for numerous institutions and businesses globally.",
                    },
                    {
                        spring: studentSpring,
                        title: "Happy Students",
                        description:
                            "Impacting lives across industries with our cutting-edge solutions tailored for edtech, real estate, tourism, and more.",
                    },
                    {
                        spring: advisorSpring,
                        title: "Advisor Ratings",
                        description:
                            "A global reputation for empowering startups to enterprises, driving growth, and transforming businesses worldwide.",
                    },
                ].map(({ spring, title, description }, index) => (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={index}
                        sx={{
                            textAlign: "center",
                            animation: `fade-in 0.5s ease ${index * 0.2}s`,
                        }}
                    >
                        <Typography
                            variant="h3"
                            sx={{
                                fontSize: { xs: "2rem", md: "2rem" },
                                fontWeight: "bold",
                                color: "#f9004d",
                                mb: 1,

                            }}
                        >
                            <animated.span>
                                {spring.number.to((n) => `${Math.floor(n)}+`)}
                            </animated.span>
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "bold",
                                color: "#212121",
                                mb: 1,
                                fontSize: { xs: "1rem", md: "1.25rem" },
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: "#5d5d62",
                                fontSize: { xs: "0.875rem", md: "1rem" },
                                lineHeight: 1.8,
                                px: 2,
                            }}
                        >
                            {description}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
            <style jsx global>{`
                @keyframes fade-in {
                    from {
                        opacity: 0;
                        transform: translateY(10px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            `}</style>
        </Box>
    );
};

export default FunFacts;
