import React from "react";
import { Box, Container, Grid, Card, CardMedia, CardContent, Typography } from "@mui/material";

const Banner2 = () => {
    return (
        <Box
            sx={{
                background: "linear-gradient(to top, #f7f9fc, #d1d9e6)",
                py: 6,
            }}
        >
            <Box sx={{position:'relative',top:100}}>
            <Container maxWidth="lg">
                {/* Heading Section */}
                <Box sx={{ textAlign: "center", mb: 6 }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }} gutterBottom>Facebook & Instagram Advertising</Typography>
                    <Typography>
                        Get a bigger place to reach out to potential customers and raise
                        awareness of your brand. Get leverage by choosing your audience,
                        setting a budget, and driving traffic.
                    </Typography>
                </Box>

                {/* Updated Cards Section */}
                <Grid container spacing={2} justifyContent="center">
                    {[
                        {
                            alt: 'Google_reviews',
                            src: 'https://cdn-hklbn.nitrocdn.com/grALJVWrmSPZyeinmmWYvzOnMqfzFnAI/assets/images/optimized/rev-5546591/idigitalise.net/wp-content/uploads/2023/06/Group-11912-1.png',
                        },
                        {
                            alt: 'Clutch_reviews',
                            src: 'https://cdn-hklbn.nitrocdn.com/grALJVWrmSPZyeinmmWYvzOnMqfzFnAI/assets/images/optimized/rev-5546591/idigitalise.net/wp-content/uploads/2023/06/Group-11913-1.png',
                        },
                        {
                            alt: 'Sortlist_reviews',
                            src: 'https://cdn-hklbn.nitrocdn.com/grALJVWrmSPZyeinmmWYvzOnMqfzFnAI/assets/images/optimized/rev-5546591/idigitalise.net/wp-content/uploads/2023/06/Group-11914-1.png',
                        },
                        {
                            alt: 'Sortlist_reviews',
                            src: 'https://cdn-hklbn.nitrocdn.com/grALJVWrmSPZyeinmmWYvzOnMqfzFnAI/assets/images/optimized/rev-5546591/idigitalise.net/wp-content/uploads/2023/06/Group-11914-1.png',
                        },
                    ].map((image, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card sx={{ width: 218, borderRadius: 2, boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                                <CardMedia
                                    component="img"
                                    height="100"
                                    image={image.src}
                                    alt={image.alt}
                                    sx={{ objectFit: "contain", }}
                                />
                                {/* <CardContent>
                                    <Typography variant="body2" color="text.secondary" align="center">
                                        {image.alt.replace('_', ' ').replace('reviews', '')}
                                    </Typography>
                                </CardContent> */}
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            </Box>
        </Box>
    );
};

export default Banner2;
