import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from '../../../../common/home/Header';
import Footer from '../../../../common/Footer';

const Faq = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Detect small screens

  const faqData = [
    {
      side: 'left',
      items: [
        { question: 'What services does SEOMitra provide?', answer: 'SEOMitra specializes in backlink building, keyword tracking, and SEO strategies to improve search engine rankings, website traffic, & conversions.' },
        { question: 'How does SEOMitra help with backlinks?', answer: 'SEOMitra builds high-quality relevant backlinks from trusted sources to improve your website is domain authority and visibility.' },
        { question: 'What is keyword tracking and why is it important?', answer: 'SEOMitra tracks the performance of your target keywords to adjust strategies and ensure optimal search engine performance.' },
        { question: 'How long does SEO take to show results?', answer: 'SEO takes time. With SEOMitra’s methods, you can expect to see results within 3–6 months, depending on your industry and competition.' },
        { question: 'Can SEOMitra handle local SEO?', answer: 'SEOMitra excels at local SEO helping businesses rank in local search results to attract customers nearby.' },
        { question: 'Does SEOMitra help with e-commerce SEO?', answer: 'Yes, SEOMitra provides tailored strategies for e-commerce businesses to improve product visibility & conversions.' },
      ],
    },
    {
      side: 'right',
      items: [
        { question: ' Why is mobile SEO important?', answer: 'Mobile SEO ensures your website is user-friendly on mobile devices. SEOMitra optimizes your site for mobile users, who now form a large portion of online traffic.' },
        { question: ' Who can benefit from SEOMitra.com?', answer: 'Many people from all over the world can benefit from this website which offers free online SEO tools. These include website owners, webmasters or web administrators, SEO professionals, writers, editors, publishers, teachers, and students.' },
        { question: ' Do you guarantee first-page rankings? ', answer: 'SEOMitra does not promise rankings, as search algorithms change often, but we ensure the best strategies are used for top results.' },
        { question: ' How does website speed Effect SEO?', answer: 'SEOMitra ensures your site loads quickly, as Google considers speed a key ranking factor.' },
        { question: ' Any registration process to use SEOMitra.com? ', answer: 'Our website does not require users to register and enter their personal information to use any of our SEO tools. However, we encourage you to register so you can use all the tools to their full potential and so that we can also send updates to your email.' },
        { question: ' What industries does SEOMitra serve? ', answer: 'SEOMitra creates customized SEO solutions for startups, small businesses & enterprises across industries.' },
      ],
    },
  ];

  return (
    <>
      <Header />
      <Grid
        sx={{ padding: isMobile ? '50px 0' : 6, background: "linear-gradient(135deg, #002966 30%, #005AE2 90%)" }}
      >
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          gutterBottom
          sx={{ color: 'white', fontWeight: 700, mt: 8 }}
        >
          Questions? We have answers
        </Typography>
        <Grid container spacing={isMobile ? 0 : 4}>
          {faqData.map((section, index) => (
            <Grid item xs={12} md={6} key={index} sx={{ mt: isMobile ? 0 : 2 }}>
              {section.items.map((item, i) => (
                <Accordion
                  key={i}
                  sx={{
                    mt: 1,
                    boxShadow: 'none',
                    color: 'white',
                    backgroundColor: '#054ada',
                    borderBottom: '1px solid white',
                    '&:before': {
                      display: 'none',
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" sx={{ fontSize: '18px', lineHeight: '29px' }}>{item.question}</Typography>
                  </AccordionSummary>
                  {item.answer && (
                    <AccordionDetails>
                      <Typography variant="body2" sx={{ fontSize: '18px', lineHeight: '28px' }}>{item.answer}</Typography>
                    </AccordionDetails>
                  )}
                </Accordion>
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default Faq;
