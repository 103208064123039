import React, { useState } from 'react';
import {
    Box,
    Typography,
    Radio,
    RadioGroup,
    Button,
    Grid,
    IconButton,
    FormControlLabel,
    TextField,
    Checkbox, Select, MenuItem,
    Card, CardContent,
    LinearProgress,
} from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import TrafficIcon from '@mui/icons-material/Traffic';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import FilterListIcon from '@mui/icons-material/FilterList';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import EventIcon from '@mui/icons-material/Event';
import Header from '../../../../common/home/Header';
import { useNavigate } from 'react-router-dom';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";

const CampaignObjective = () => {

    const [showEndDate, setShowEndDate] = useState(false);
    const handleCheckboxChange = (event) => {
        setShowEndDate(event.target.checked);
    };
    const [location, setLocation] = useState("India");
    const [age, setAge] = useState("18 - 65+");
    const [gender, setGender] = useState("All genders");
    const [isEditingLocation, setIsEditingLocation] = useState(false);
    const [isEditingAge, setIsEditingAge] = useState(false);
    const [isEditingGender, setIsEditingGender] = useState(false);

    const handleEdit = (field) => {
        switch (field) {
            case "location":
                setIsEditingLocation(true);
                break;
            case "age":
                setIsEditingAge(true);
                break;
            case "gender":
                setIsEditingGender(true);
                break;
            default:
                break;
        }
    };

    const handleSave = (field) => {
        switch (field) {
            case "location":
                setIsEditingLocation(false);
                break;
            case "age":
                setIsEditingAge(false);
                break;
            case "gender":
                setIsEditingGender(false);
                break;
            default:
                break;
        }
    };

    const pricingCategories = {
        real_estate: [
            {
                title: "Starter",
                price: "₹80/mo",
                descriptions: [
                    "Limited access to Site Explorer, Keywords Explorer, and Site Audit."
                ],
                list: [
                    "- Backlinks from Blog Sites",
                    "- Total 500 Backlinks",
                    "- For 1 Website URL",
                    "- For up to 10 Keywords",
                    "- 100 % DoFollow Backlinks",
                    "- Permanent Backlinks",
                    "- Free Premium Indexing"
                ],
                buttonText: "Get started",
                isNew: true,
                backgroundColor: "#0a78be",
                textColor: "#ffff",
                buttonColor: "#FF9800",
                progress: 80,
            },
        ],
    };

    const objectives = [
        {
            label: 'Awareness',
            icon: <CampaignIcon fontSize="large" style={{ color: '#0a78be' }} />,
            description: 'Show your ads to people who are most likely to remember them.',
            goodFor: ['Reach', 'Brand awareness', 'Video views', 'Store location awareness'],
        },
        {
            label: 'Traffic',
            icon: <TrafficIcon fontSize="large" style={{ color: '#0a78be' }} />,
            description: 'Drive people to your website or app.',
            goodFor: ['Website traffic', 'App traffic'],
        },
        {
            label: 'Engagement',
            icon: <ChatBubbleOutlineIcon fontSize="large" style={{ color: '#0a78be' }} />,
            description: 'Get more people to interact with your content.',
            goodFor: ['Post engagement', 'Page likes', 'Event responses'],
        },
        {
            label: 'Leads',
            icon: <FilterListIcon fontSize="large" style={{ color: '#0a78be' }} />,
            description: 'Collect leads for your business.',
            goodFor: ['Lead forms', 'Calls', 'Sign-ups'],
        },
        {
            label: 'App promotion',
            icon: <AppShortcutIcon fontSize="large" style={{ color: '#0a78be' }} />,
            description: 'Get people to install your app.',
            goodFor: ['App installs', 'App engagement'],
        },
        {
            label: 'Sales',
            icon: <EventIcon fontSize="large" style={{ color: '#0a78be' }} />,
            description: 'Increase sales or conversions.',
            goodFor: ['Conversions', 'Catalog sales'],
        },
    ];

    const [selectedObjective, setSelectedObjective] = React.useState("");
    const [hoveredObjective, setHoveredObjective] = React.useState(null);
    const [currentStep, setCurrentStep] = React.useState(1); 

    const handleObjectiveChange = (label) => {
        const newObjective = objectives.find((obj) => obj.label === label);
        setSelectedObjective(newObjective);
        setHoveredObjective(null);
    };

    const handleHover = (objective) => {
        setHoveredObjective(objective);
    };

    const handleHoverLeave = () => {
        setHoveredObjective(null);
    };

    const displayedObjective = hoveredObjective || selectedObjective;
    const navigate = useNavigate();

    const handleContinue = () => {
        if (currentStep === 1) {
            if (selectedObjective) {
                setCurrentStep(2); 
            } else {
                alert("Please select an objective before continuing.");
            }
        } else {
            const serializableObjective = {
                label: selectedObjective.label,
                description: selectedObjective.description,
                goodFor: selectedObjective.goodFor,
            };
            navigate(`/payment-ads`, { state: { objective: serializableObjective } });
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1); 
        }
    };

    return (
        <>
            <Header />
            <Grid container display={'flex'} flexDirection={'row'}>
                <Grid
                    md={8}
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{ backgroundColor: '#ffff', padding: '20px', paddingTop: 8 }}
                >
                    {currentStep === 1 && (
                        <Grid
                            container
                            spacing={4}
                            sx={{
                                backgroundColor: '#ffff',
                                borderRadius: 8,
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
                                padding: 4,
                                maxWidth: 800,
                                width: '100%',
                            }}
                        >
                            {/* Left Side: Objectives List */}
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                                    Choose a campaign objective
                                </Typography>
                                <RadioGroup value={selectedObjective.label}>
                                    {objectives.map((objective) => (
                                        <Box
                                            key={objective.label}
                                            sx={{
                                                mb: 1,
                                                borderRadius: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                                height: '15px',
                                                p: 2,
                                                cursor: 'pointer',
                                                bgcolor:
                                                    selectedObjective.label === objective.label
                                                        ? '#e1edf7'
                                                        : 'transparent',
                                                '&:hover': {
                                                    bgcolor: '#f0f0f0',
                                                },
                                                transition: 'all 0.3s ease',
                                            }}
                                            onClick={() => handleObjectiveChange(objective.label)}
                                            onMouseEnter={() => handleHover(objective)}
                                            onMouseLeave={handleHoverLeave}
                                        >
                                            <Radio
                                                value={objective.label}
                                                checked={selectedObjective.label === objective.label}
                                                sx={{
                                                    color: '#0a78be',
                                                    '&.Mui-checked': {
                                                        color: '#0a78be',
                                                    },
                                                }}
                                            />
                                            <IconButton disableRipple sx={{ color: '#0a78be' }}>
                                                {objective.icon}
                                            </IconButton>
                                            <Typography sx={{ fontWeight: 500 }}>
                                                {objective.label}
                                            </Typography>
                                        </Box>
                                    ))}
                                </RadioGroup>
                            </Grid>

                            {/* Right Side: Hovered or Selected Objective Details */}
                            <Grid item xs={12} md={5}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        {displayedObjective.icon}
                                        <Typography variant="h6" fontWeight="bold">
                                            {displayedObjective.label}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        {displayedObjective.description}
                                    </Typography>
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1 }}>
                                            Good For:
                                        </Typography>
                                        {displayedObjective.goodFor?.map((item) => (
                                            <Typography
                                                key={item}
                                                variant="body2"
                                                sx={{
                                                    backgroundColor: '#e1edf7',
                                                    borderRadius: 4,
                                                    padding: '4px 8px',
                                                    display: 'inline-block',
                                                    marginBottom: '4px',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        ))}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    )}

                    {currentStep === 2 && (
                        <Grid
                            container
                            spacing={4}
                            sx={{
                                backgroundColor: '#ffff',
                                borderRadius: 8,
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
                                padding: 4,
                                maxWidth: 700,
                                width: '100%',
                                height: '450px',
                                overflow: 'hidden',
                            }}
                        >
                            <Typography variant="h5" fontWeight="bold">
                                Step 2: Customize Your Campaign
                            </Typography>

                            <Box
                                sx={{
                                    width: '100%',
                                    marginTop: 2,
                                    overflowY: 'auto', 
                                    height: 'calc(100% - 50px)', 
                                }}
                            >
                                <TextField
                                    fullWidth
                                    label="Ad Set Name"
                                    variant="outlined"
                                    sx={{ marginBottom: 2, mt: 1 }}
                                />

                                {/* <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1 }}>
                                Conversion Location
                            </Typography> */}
                                <RadioGroup defaultValue="instantForms" name="conversion-location">
                                    <Box sx={{ width: '100%' }}>
                                        <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1 }}>
                                            Conversion Location
                                        </Typography>
                                        <RadioGroup defaultValue="instantForms" name="conversion-location">
                                            <FormControlLabel
                                                value="website"
                                                control={<Radio sx={{
                                                    color: '#0a78be',
                                                    '&.Mui-checked': {
                                                        color: '#0a78be',
                                                    },
                                                }} />}
                                                label="Website - Generate leads through your website."
                                            />
                                            <FormControlLabel
                                                value="websiteAndCalls"
                                                control={<Radio sx={{
                                                    color: '#0a78be',
                                                    '&.Mui-checked': {
                                                        color: '#0a78be',
                                                    },
                                                }} />}
                                                label="Website and Calls - Generate leads through both your website and calls."
                                            />
                                            <FormControlLabel
                                                value="instantForms"
                                                control={<Radio sx={{
                                                    color: '#0a78be',
                                                    '&.Mui-checked': {
                                                        color: '#0a78be',
                                                    },
                                                }} />}
                                                label="Instant Forms - Generate leads by asking people to fill in a form."
                                            />
                                            <FormControlLabel
                                                value="messenger"
                                                control={<Radio sx={{
                                                    color: '#0a78be',
                                                    '&.Mui-checked': {
                                                        color: '#0a78be',
                                                    },
                                                }} />}
                                                label="Messenger - Generate leads by starting chats in Messenger."
                                            />
                                            <FormControlLabel
                                                value="instantFormsAndMessenger"
                                                control={<Radio sx={{
                                                    color: '#0a78be',
                                                    '&.Mui-checked': {
                                                        color: '#0a78be',
                                                    },
                                                }} />}
                                                label="Instant Forms and Messenger - Generate leads by asking people to fill in a form or by starting chats in Messenger."
                                            />
                                            <FormControlLabel
                                                value="calls"
                                                control={<Radio sx={{
                                                    color: '#0a78be',
                                                    '&.Mui-checked': {
                                                        color: '#0a78be',
                                                    },
                                                }} />}
                                                label="Calls - Generate leads by asking people to call your business."
                                            />
                                            <FormControlLabel
                                                value="app"
                                                control={<Radio sx={{
                                                    color: '#0a78be',
                                                    '&.Mui-checked': {
                                                        color: '#0a78be',
                                                    },
                                                }} />}
                                                label="App - Generate leads through your app."
                                            />
                                        </RadioGroup>

                                    </Box>
                                </RadioGroup>

                                <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1 }}>
                                    Schedule
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginBottom: 2 }}>
                                    <TextField
                                        label="Start Date"
                                        type="date"
                                        defaultValue="2025-01-16"
                                        sx={{ flex: 1 }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        label="Time"
                                        type="time"
                                        defaultValue="11:40"
                                        sx={{ flex: 1 }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                                <FormControlLabel
                                    control={<Checkbox checked={showEndDate} onChange={handleCheckboxChange} />}
                                    label="Set an end date"
                                    sx={{ marginBottom: 2 }}
                                />
                                {showEndDate && (
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
                                        <Select
                                            defaultValue="custom"
                                            sx={{ flex: 1 }}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'End Date Type' }}
                                        >
                                            <MenuItem value="custom">Custom</MenuItem>
                                        </Select>
                                        <TextField
                                            label="End Date"
                                            type="date"
                                            defaultValue="2025-01-23"
                                            sx={{ flex: 1 }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        <TextField
                                            label="Time"
                                            type="time"
                                            defaultValue="11:40"
                                            sx={{ flex: 1 }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        maxWidth: 600,
                                        border: "1px solid #ddd",
                                        borderRadius: 2,
                                        padding: 3,
                                        backgroundColor: "#fff",
                                        fontFamily: "Arial, sans-serif",
                                    }}
                                >
                                    {/* Custom Audiences */}
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            Custom Audiences
                                        </Typography>
                                        <Button variant="text" size="small" sx={{ textTransform: "none" }}>
                                            Create new
                                        </Button>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        placeholder="Search existing audiences"
                                        size="small"
                                        sx={{ marginTop: 2 }}
                                    />
                                    <Button
                                        variant="outlined"
                                        startIcon={<AddCircleOutlineIcon />}
                                        sx={{
                                            textTransform: "none",
                                            marginTop: 2,
                                            color: "#000",
                                            borderColor: "#ddd",
                                        }}
                                    >
                                        Add exclusions
                                    </Button>

                                    {/* Location */}
                                    <Box marginTop={3}>
                                        <Box display="flex" alignItems="center">
                                            <Typography fontSize={14} fontWeight="bold">
                                                * Locations
                                            </Typography>
                                            <IconButton size="small">
                                                <InfoOutlinedIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            {isEditingLocation ? (
                                                <TextField
                                                    value={location}
                                                    onChange={(e) => setLocation(e.target.value)}
                                                    size="small"
                                                    fullWidth
                                                />
                                            ) : (
                                                <Typography fontSize={14}>{location}</Typography>
                                            )}
                                            <IconButton
                                                size="small"
                                                onClick={() => handleEdit("location")}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        {isEditingLocation && (
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                sx={{ marginTop: 1 }}
                                                onClick={() => handleSave("location")}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </Box>

                                    {/* Age */}
                                    <Box marginTop={3}>
                                        <Box display="flex" alignItems="center">
                                            <Typography fontSize={14} fontWeight="bold">
                                                Age
                                            </Typography>
                                            <IconButton size="small">
                                                <InfoOutlinedIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            {isEditingAge ? (
                                                <TextField
                                                    value={age}
                                                    onChange={(e) => setAge(e.target.value)}
                                                    size="small"
                                                    fullWidth
                                                />
                                            ) : (
                                                <Typography fontSize={14}>{age}</Typography>
                                            )}
                                            <IconButton
                                                size="small"
                                                onClick={() => handleEdit("age")}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        {isEditingAge && (
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                sx={{ marginTop: 1 }}
                                                onClick={() => handleSave("age")}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </Box>

                                    {/* Gender */}
                                    <Box marginTop={3}>
                                        <Box display="flex" alignItems="center">
                                            <Typography fontSize={14} fontWeight="bold">
                                                Gender
                                            </Typography>
                                            <IconButton size="small">
                                                <InfoOutlinedIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            {isEditingGender ? (
                                                <TextField
                                                    value={gender}
                                                    onChange={(e) => setGender(e.target.value)}
                                                    size="small"
                                                    fullWidth
                                                />
                                            ) : (
                                                <Typography fontSize={14}>{gender}</Typography>
                                            )}
                                            <IconButton
                                                size="small"
                                                onClick={() => handleEdit("gender")}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        {isEditingGender && (
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                sx={{ marginTop: 1 }}
                                                onClick={() => handleSave("gender")}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </Box>

                                    {/* Save Button */}
                                    {/* <Box marginTop={3}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            sx={{
                                                backgroundColor: "#1877F2",
                                                textTransform: "none",
                                                color: "#fff",
                                                "&:hover": { backgroundColor: "#145dbf" },
                                            }}
                                        >
                                            Save audience
                                        </Button>
                                    </Box> */}
                                </Box>

                            </Box>

                        </Grid>

                    )}

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 4,
                            width: '100%',
                            maxWidth: 800,
                            ml: -7
                        }}
                    >
                        {currentStep > 1 && (
                            <Button
                                variant="outlined"
                                sx={{ borderColor: '#000', color: '#000', mr: 2 }}
                                onClick={handleBack}
                            >
                                Back
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            sx={{ bgcolor: '#0a78be', }}
                            onClick={handleContinue}
                        >
                            {currentStep === 1 ? 'Continue' : 'Finish'}
                        </Button>
                    </Box>
                </Grid>
                <Grid container md={4} spacing={2} mt={2}>
                    {pricingCategories.real_estate.map((plan, index) => (
                        <Grid item key={index}>
                            <Box>
                                <Card
                                    sx={{
                                        background: "linear-gradient(135deg, #002966 30%, #005AE2 90%)",
                                        color: plan.textColor,
                                        height: "500px",
                                        width: "320px",
                                        borderRadius: 5,
                                        boxShadow: "0 4px 15px rgba(163, 212, 249, 0.93)",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        position: "relative",
                                    }}
                                >
                                    <CardContent>
                                        {plan.isNew && (
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    marginLeft: "240px",
                                                    backgroundColor: "#ff9800",
                                                    padding: "3px 10px",
                                                    borderRadius: "5px",
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                    color: "#fff",
                                                }}
                                            >
                                                New
                                            </Box>
                                        )}
                                        <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, }}>
                                            {plan.title}
                                        </Typography>
                                        <Typography variant="h4" fontWeight="bold">
                                            {plan.price}
                                        </Typography>
                                        <Box sx={{ paddingTop: "3px", paddingBottom: "10px" }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: plan.buttonColor,
                                                    color: "#ffffff",
                                                    width: "70%",
                                                    padding: "10px",
                                                    borderRadius: "5px",
                                                    textTransform: "none",
                                                    fontWeight: "bold",
                                                    ":hover": { backgroundColor: "#FFB74D" },
                                                }}
                                            >
                                                {plan.buttonText}
                                            </Button>
                                        </Box>
                                        {plan.descriptions.map((desc, index) => (
                                            <Typography
                                                key={index}
                                                sx={{
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    color: "#ffff",
                                                    mb: "10px",
                                                }}
                                            >
                                                {desc}
                                            </Typography>
                                        ))}
                                        <Box sx={{ mt: 2, mb: 2 }}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={plan.progress}
                                                sx={{
                                                    backgroundColor: "#E0E0E0",
                                                    "& .MuiLinearProgress-bar": { backgroundColor: "#00C853" },
                                                }}
                                            />
                                        </Box>
                                        {plan.list.map((item, index) => (
                                            <Box key={index} sx={{ display: "flex", alignItems: "center", mb: "10px" }}>
                                                <Typography sx={{ fontSize: "16px", lineHeight: "24px", color: "#ffff" }}>
                                                    {item}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

export default CampaignObjective;