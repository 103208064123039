import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    LinearProgress,
    TextField,
    MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const pricingCategories = {
    real_estate: [
        {
            id: 1,
            title: "Starter",
            price: "80",
            descriptions: [
                "Limited access to Site Explorer, Keywords Explorer, and Site Audit."
            ],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
                // "Detailed Backlinks Report",
                // "White Label Report",
                // "Report in 15 Working Days",
            ],
            buttonText: "Get started",
            isNew: true,
            backgroundColor: "#002966",
            textColor: "#ffff",
            buttonColor: "#FF9800",
            progress: 70,
        },
        {
            id: 2,
            title: "Standard",
            price: "85",
            descriptions: ["Essential data for small businesses and hobby projects."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 1000 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 80,
        },
        {
            id: 3,
            title: "Premium",
            price: "90",
            descriptions: ["Perfect for marketing consultants and freelancers."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 2500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 95,
        },
    ],
    // real_estate: [
    //     {
    //         title: "Starter",
    //         price: "100",
    //         descriptions: ["Basic tools for real estate analysis."],
    //         list: ["Access to limited market data.", "Suitable for beginners."],
    //         buttonText: "Get started",
    //         isNew: true,
    //         backgroundColor: "#002966",
    //         textColor: "#ffffff",
    //         buttonColor: "#FF9800",
    //         progress: 10,
    //     },
    //     {
    //         title: "Standard",
    //         price: "95/per lead ",
    //         descriptions: ["Advanced tools for small real estate firms."],
    //         list: ["Comprehensive data.", "Market trends analysis."],
    //         buttonText: "Get started",
    //         backgroundColor: "#ffffff",
    //         textColor: "#000000",
    //         buttonColor: "#FF9800",
    //         progress: 60,
    //     },
    //     {
    //         title: "Premium",
    //         price: "90/per lead",
    //         descriptions: ["Complete suite for real estate professionals."],
    //         list: ["Full market insights.", "Custom reporting tools."],
    //         buttonText: "Get started",
    //         backgroundColor: "#ffffff",
    //         textColor: "#000000",
    //         buttonColor: "#FF9800",
    //         progress: 90,
    //     },
    // ],
    education: [
        {
            id: 4,
            title: "Starter",
            price: "55",
            descriptions: ["Basic tools for real estate analysis."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            isNew: true,
            backgroundColor: "#002966",
            textColor: "#ffffff",
            buttonColor: "#FF9800",
            progress: 70,
        },
        {
            id: 5,
            title: "Standard",
            price: "60",
            descriptions: ["Advanced tools for small real estate firms."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 1000 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 75,
        },
        {
            id: 6,
            title: "Premium",
            price: "70",
            descriptions: ["Complete suite for real estate professionals."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 2500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 80,
        },
    ],
    home_appliances: [
        {
            id: 7,
            title: "Starter",
            price: "70",
            descriptions: ["Basic tools for real estate analysis."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            isNew: true,
            backgroundColor: "#002966",
            textColor: "#ffffff",
            buttonColor: "#FF9800",
            progress: 70,
        },
        {
            id: 8,
            title: "Standard",
            price: "75",
            descriptions: ["Advanced tools for small real estate firms."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 1000 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 75,
        },
        {
            id: 9,
            title: "Premium",
            price: "80",
            descriptions: ["Complete suite for real estate professionals."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 2500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 80,
        },
    ],
    travel: [
        {
            id: 10,
            title: "Starter",
            price: "60",
            descriptions: ["Basic tools for real estate analysis."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            isNew: true,
            backgroundColor: "#002966",
            textColor: "#ffffff",
            buttonColor: "#FF9800",
            progress: 60,
        },
        {
            id: 11,
            title: "Standard",
            price: "65",
            descriptions: ["Advanced tools for small real estate firms."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 1000 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 65,
        },
        {
            id: 12,
            title: "Premium",
            price: "70",
            descriptions: ["Complete suite for real estate professionals."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 2500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 70,
        },
    ],
    ecommerce: [
        {
            id: 13,
            title: "Starter",
            price: "60",
            descriptions: ["Basic tools for real estate analysis."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            isNew: true,
            backgroundColor: "#002966",
            textColor: "#ffffff",
            buttonColor: "#FF9800",
            progress: 60,
        },
        {
            id: 14,
            title: "Standard",
            price: "65",
            descriptions: ["Advanced tools for small real estate firms."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 1000 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 65,
        },
        {
            id: 15,
            title: "Premium",
            price: "70",
            descriptions: ["Complete suite for real estate professionals."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 2500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 70,
        },
    ],
    hospital: [
        {
            id: 16,
            title: "Starter",
            price: "70",
            descriptions: ["Basic tools for real estate analysis."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            isNew: true,
            backgroundColor: "#002966",
            textColor: "#ffffff",
            buttonColor: "#FF9800",
            progress: 70,
        },
        {
            id: 17,
            title: "Standard",
            price: "80",
            descriptions: ["Advanced tools for small real estate firms."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 1000 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 80,
        },
        {
            id: 18,
            title: "Premium",
            price: "90",
            descriptions: ["Complete suite for real estate professionals."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 2500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 90,
        },
    ],
    loans: [
        {
            id: 19,
            title: "Starter",
            price: "60",
            descriptions: ["Basic tools for real estate analysis."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            isNew: true,
            backgroundColor: "#002966",
            textColor: "#ffffff",
            buttonColor: "#FF9800",
            progress: 60,
        },
        {
            id: 20,
            title: "Standard",
            price: "75",
            descriptions: ["Advanced tools for small real estate firms."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 1000 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 75,
        },
        {
            id: 21,
            title: "Premium",
            price: "80",
            descriptions: ["Complete suite for real estate professionals."],
            list: [
                "- Backlinks from Blog Sites",
                "- Total 2500 Backlinks",
                "- For 1 Website URL",
                "- For up to 10 Keywords",
                "- 100 % DoFollow Backlinks",
                "- Permanent Backlinks",
                "- Free Premium Indexing",
            ],
            buttonText: "Get started",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            buttonColor: "#FF9800",
            progress: 80,
        },
    ],

};



const PricingCard = ({ plan }) => {
    const navigate = useNavigate();
    const [inCart, setInCart] = useState(false);

    useEffect(() => {
        const existingCart = Cookies.get("facebookAndInstaAdsCart") ? JSON.parse(Cookies.get("facebookAndInstaAdsCart")) : [];
        const isItemInCart = existingCart.some((item) => item.id === plan.id);
        setInCart(isItemInCart);
    }, [plan.id]);

    const handleRemoveFromCart = () => {
        const existingCart = Cookies.get("facebookAndInstaAdsCart") ? JSON.parse(Cookies.get("facebookAndInstaAdsCart")) : [];
        const updatedCart = existingCart.filter((item) => item.id !== plan.id);
        Cookies.set("facebookAndInstaAdsCart", JSON.stringify(updatedCart), { expires: 7 });
        setInCart(false);
    };

    const handleButtonClick = () => {
        Cookies.remove("facebookAndInstaAdsCart");
        const existingCart = Cookies.get("facebookAndInstaAdsCart") ? JSON.parse(Cookies.get("facebookAndInstaAdsCart")) : [];
        if (!existingCart.some((item) => item.id === plan.id)) {
            const updatedCart = [...existingCart, { id: plan.id, name: plan.title, price: plan.price }];
            Cookies.set("facebookAndInstaAdsCart", JSON.stringify(updatedCart), { expires: 7 });
            // setInCart(true);
        }
        navigate("/adsmanager");
    };

    return (
        <Box>
            <Card
                sx={{
                    backgroundColor: plan.backgroundColor,
                    color: plan.textColor,
                    height: "500px",
                    width: "320px",
                    borderRadius: "0px",
                    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    position: "relative",
                }}
            >
                <CardContent>
                    {plan.isNew && (
                        <Box
                            sx={{
                                position: "absolute",
                                marginLeft: "240px",
                                backgroundColor: "#ff9800",
                                padding: "3px 10px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#fff",
                            }}
                        >
                            Lite
                        </Box>
                    )}
                    <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                        {plan.title}
                    </Typography>
                    <Typography variant="h4" fontWeight="bold">
                        ₹{plan.price}/mo
                    </Typography>
                    <Box sx={{ paddingTop: "3px", paddingBottom: "10px",display:"flex",alignItems:"center",gap:1 }}>
                        {inCart && (
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "red",
                                    color: "#ffffff",
                                    width: "70%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                }}
                                onClick={handleRemoveFromCart}
                            >
                                Remove Cart
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: plan.buttonColor,
                                color: "#ffffff",
                                width: "70%",
                                padding: "10px",
                                borderRadius: "5px",
                                textTransform: "none",
                                fontWeight: "bold",
                                ":hover": { backgroundColor: "#FFB74D" },
                            }}
                            onClick={handleButtonClick} // Corrected navigation onClick
                        >
                            {plan.buttonText}
                        </Button>
                    </Box>
                    {plan.descriptions.map((desc, index) => (
                        <Typography
                            key={index}
                            sx={{
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#737373",
                                mb: "10px",
                            }}
                        >
                            {desc}
                        </Typography>
                    ))}
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <LinearProgress
                            variant="determinate"
                            value={plan.progress}
                            sx={{
                                backgroundColor: "#E0E0E0",
                                "& .MuiLinearProgress-bar": { backgroundColor: "#00C853" },
                            }}
                        />
                    </Box>
                    {plan.list.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: "10px"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    color: "#737373"
                                }}
                            >
                                {item}
                            </Typography>
                        </Box>
                    ))}
                </CardContent>
            </Card>
        </Box>
    );
};

const PricingSection = () => {
    const [selectedCategory, setSelectedCategory] = useState("real_estate");
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    return (
        <Box
            sx={{
                background: "linear-gradient(135deg, #002966 30%, #005AE2 90%)",
                padding: "50px 0",
                color: "#ffffff",
            }}
        >
            <Box sx={{ mx: "auto", maxWidth: "1200px", px: 3, mb: 5 }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="h3" fontWeight="bold" sx={{ ml: 10 }}>
                        <span style={{ color: "#ffffff" }}>Get Started:</span>{" "}
                        <span style={{ color: "#7F7F7F" }}>
                            Pick a plan that suits your needs
                        </span>
                    </Typography>
                </Grid>
            </Box>

            <Box sx={{ mx: "auto", maxWidth: "1200px", px: 3 }}>
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
                    <Box sx={{ marginRight: 2, ml: 72.5 }}>
                        <Typography variant="body1" sx={{ color: "#fff", fontWeight: "bold" }}>
                            Select Your Categories:
                        </Typography>
                    </Box>

                    <Box sx={{ flex: "2" }}>
                        <TextField
                            select
                            size="small"
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            sx={{
                                width: "300px",
                                "& .MuiOutlinedInput-root": {
                                    color: "#fff",
                                    "& fieldset": {
                                        borderColor: "#e6f7ff",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#e6f7ff",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#e6f7ff",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    color: "#fff",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: "#fff",
                                },
                                "& .MuiSelect-icon": {
                                    color: "#fff",
                                },
                            }}
                        >
                            <MenuItem value="real_estate">Real Estate</MenuItem>
                            <MenuItem value="education">Education</MenuItem>
                            <MenuItem value="home_appliances">Home Appliances</MenuItem>
                            <MenuItem value="travel">Travel</MenuItem>
                            <MenuItem value="ecommerce">Ecommerce</MenuItem>
                            <MenuItem value="hospital">Hospital</MenuItem>
                            <MenuItem value="loans">Loans</MenuItem>
                        </TextField>
                    </Box>
                </Box>

                <Grid display={'flex'} justifyContent="center" alignItems="stretch">
                    {pricingCategories[selectedCategory]?.map((plan, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <PricingCard plan={plan} />
                        </Grid>
                    ))}
                </Grid>

            </Box>
        </Box>
    );
};

export default PricingSection;