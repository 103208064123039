import React from "react";
import { Box, Button, Typography } from "@mui/material";
import backgroundImage from "../../../../assets/seobannerimage.png";

const SeoBanner = () => {
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "470px",
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover", 
                backgroundPosition: "center", 
                backgroundRepeat: "no-repeat", 
                color: "#000",
                padding: "40px",
                mt:2
            }}
        >

            {/* Content Box */}
            <Box
                sx={{
                    borderRadius: "8px",
                    background: "rgba(255, 255, 255, 0.7)", 
                    padding: "20px", 
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        fontFamily: 'Poppins, sans-serif', // Apply Poppins font family
                        fontWeight: "700", // For bold weight
                        fontSize: "38px",
                        lineHeight: "1.2",
                        marginBottom: "16px",
                    }}
                >
                    Professional SEO Services <br /> Company in Bangalore
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: "16px",
                        lineHeight: "1.6",
                        marginBottom: "24px",
                        color: "#555",
                        width: '570px'
                    }}
                >
                    Ralecon, an experienced SEO Agency in Bangalore, brings 11 years of
                    industry experience to the table. Our expert team provides excellent
                    SEO services and has a proven track record of success. We have the
                    skills to help your business thrive online, with 500+ delighted
                    clients, 20k+ keywords placed in Google’s top 10 (SERP), and 2 lakh+
                    organic leads provided. Trust us to increase your online visibility
                    and achieve substantial growth.
                </Typography>
                <Button
                    variant="contained"
                    sx={{
                        background: "linear-gradient(to right, #2197b7, #259ba0)",
                        color: "#fff",
                        padding: "12px 24px",
                        borderRadius: "8px",
                        fontWeight: "bold",
                        textTransform: "none",
                        fontSize: "16px",
                    }}
                >
                    Get Started
                </Button>
            </Box>
        </Box>
    );
};

export default SeoBanner;
