import React from "react";
import { Box, Typography } from "@mui/material";
import image from '../../../../../assets/fb-advertising-process.png'

const FacebookAdvertisingProcess = () => {
    // Data for each phase
    const phases = [
        { title: "Discovery", color: "#B19CD9", icon: "🔍" },
        { title: "Strategy", color: "#FF9AA2", icon: "📊" },
        { title: "Execution", color: "#85C1E9", icon: "⚙️" },
        { title: "Optimization", color: "#ABEBC6", icon: "📈" },
        { title: "Management", color: "#F7DC6F", icon: "🛠️" },
        { title: "Analysis & Reporting", color: "#F5B7B1", icon: "📑" },
    ];

    return (
        <Box
            sx={{
                backgroundColor: "#ffff",
                textAlign: "center",
                py: 6,
                px: 3,
            }}
        >
            {/* Section Heading */}
            <Typography variant="h3" fontWeight="bold" gutterBottom>
                How Our <span style={{ color: "#3498DB" }}>Facebook Advertising</span>{" "}
                Process Works
            </Typography>
            <Typography
                variant="body1"
                color="textSecondary"
                sx={{ maxWidth: "900px", mx: "auto", mb: 5 }}
            >
                Our Facebook advertising agency grows the budget and audience of our
                client’s Facebook advertising campaign while maintaining or raising the
                return on investment (ROI). With the help of resources like the
                Facebook Meta Ads Library, we follow the below procedure to scale a
                campaign:
            </Typography>

            <Box
                sx={{
                    position: "relative",
                    top:10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 4,
                }}
            >
                {/* Central Image Section */}
                <Box
                    component="img"
                    src={image}
                    alt="Facebook Advertising Process"
                    sx={{ width: '800px', height: '350px', }}
                />
            </Box>
        </Box>
    );
};

export default FacebookAdvertisingProcess;
