import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import img1 from '../../../assets/Jamboree.jpg';
import img2 from '../../../assets/Deliveroo-Logo.png';
import img3 from '../../../assets/mc.png';
import img4 from '../../../assets/HCL-1-1.png';
import img5 from '../../../assets/physics-wallah.png';
import img6 from '../../../assets/cocaCola.png';

const Organizations = () => {
    return (
        <Box sx={{ backgroundColor: 'white', py: { xs: 4, md: 6 } }}>
            {/* Text Section */}
            <Box sx={{ textAlign: 'center', mb: 4, px: { xs: 2, sm: 4 } }}>
                <Typography
                    variant="h5"
                    component="h2"
                    sx={{
                        fontWeight: 'bold',
                        color: '#333',
                        margin: '0 auto',
                        fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem' },
                    }}
                >
                    Trusted by Over 5k+ Businesses for Backlink and Keyword Tracking Services
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: '#666',
                        margin: '0 auto',
                        mt: 2,
                        fontSize: { xs: '0.9rem', sm: '1rem' },
                        maxWidth: 800,
                    }}
                >
                    From Digital Marketing Agencies, SEO Experts, E-commerce Websites, Content Creators, and Bloggers to Enterprises and Startups, everyone trusts us for reliable backlink services and advanced keyword tracking tools. Boost your online presence with SEO Mitra!
                </Typography>
            </Box>

            {/* Images Section */}
            <Grid
                container
                spacing={2} 
                sx={{
                    justifyContent: 'center',
                    maxWidth: 1200,
                    margin: '0 auto',
                    px: { xs: 3, sm: 4 },
                    ml: { xs: -3, md: 12 }
                }}
            >
                {[img1, img2, img3, img4, img5, img6].map((img, index) => (
                    <Grid item xs={6} sm={4} md={2} key={index}>
                        <Box
                            component="img"
                            src={img}
                            alt={`Organization ${index + 1}`}
                            sx={{
                                width: '100%',
                                borderRadius: 2,
                                height: { xs: '60px', sm: '70px', md: '80px' },
                                objectFit: 'contain',
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Organizations;
