// import React, { useState, useRef } from 'react';
// import {
//   Drawer,
//   List,
//   ListItem,
//   ListItemIcon,
//   Popover,
//   Typography,
//   Toolbar,
//   Box,
//   Divider,
// } from '@mui/material';
// import KeyIcon from '@mui/icons-material/VpnKey';
// import LayersIcon from '@mui/icons-material/Layers';
// import SearchIcon from '@mui/icons-material/Search';
// import AssessmentIcon from '@mui/icons-material/Assessment';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import SettingsIcon from '@mui/icons-material/Settings';
// import { useNavigate } from 'react-router-dom';
// import { useTheme } from '../../../../common/ThemeContext';

// const drawerWidth = 70;

// const Dashboardsideicon = () => {
//   const { theme } = useTheme();
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [selectedIndex, setSelectedIndex] = useState(null);
//   const navigate=useNavigate();

//   const popoverRef = useRef(null);

//   // Icon configuration array
//   const icons = [
//     { name: 'Key', icon: <KeyIcon />, subItems: [{ name: 'Sub Key 1' }, { name: 'Sub Key 2' }], highlighted: true },
//     { name: 'Layers', icon: <LayersIcon />, subItems: [], highlighted: false },
//     { name: 'Search', icon: <SearchIcon />, subItems: [], highlighted: false },
//     { name: 'Assessment', icon: <AssessmentIcon />, subItems: [], highlighted: false },
//     { name: 'Notifications', icon: <NotificationsIcon />, subItems: [], highlighted: false },
//     { name: 'Settings', icon: <SettingsIcon />, subItems: [{ name: 'Packages',path:'/packages' },], highlighted: false, bottom: true },
//   ];

//   // Define colors for light and dark modes
//   const backgroundColor = theme.palette.mode === 'dark' ? '#ffffff' : '#312b36';
//   const textColor = theme.palette.mode === 'dark' ? '#000' : '#fff';
//   const iconColorHighlighted = '#F76D1E';
//   const iconColorRegular = theme.palette.mode === 'dark' ? '#888' : '#8a859f';

//   const handlePopoverOpen = (event, index) => {
//     setAnchorEl(event.currentTarget);
//     setOpen(true);
//     setSelectedIndex(index);
//   };

//   const handlePopoverClose = () => {
//     setOpen(false);
//     setAnchorEl(null);
//   };

//   const handleIconClick = (path) => {
//     navigate(path);
//     alert(`${path} icon clicked!`);
//   };

//   return (
//     <Drawer
//       variant="permanent"
//       sx={{
//         width: drawerWidth,
//         flexShrink: 0,
//         [`& .MuiDrawer-paper`]: {
//           width: drawerWidth,
//           boxSizing: 'border-box',
//           backgroundColor,
//           color: textColor,
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           mt: 8,
//           pl: 4,
//         },
//       }}
//     >
//       <Toolbar />
//       <Box sx={{ overflow: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//         <List>
//           {icons.map((item, index) => (
//             !item.bottom && (
//               <ListItem
//                 key={index}
//                 button
//                 sx={{ justifyContent: 'center', padding: '20px 0', cursor: 'pointer' }}
//                 onClick={(event) => {
//                   if (item.subItems.length > 0) {
//                     handlePopoverOpen(event, index);
//                   } else {
//                     handleIconClick(item.name);
//                   }
//                 }}
//               >
//                 <ListItemIcon>
//                   {React.cloneElement(item.icon, { style: { color: item.highlighted ? iconColorHighlighted : iconColorRegular } })}
//                 </ListItemIcon>
//               </ListItem>
//             )
//           ))}
//         </List>
//         <Box sx={{ flexGrow: 1 }} />
//         <List>
//           {icons.map((item, index) => (
//             item.bottom && (
//               <ListItem
//                 key={index}
//                 button
//                 sx={{ justifyContent: 'center', padding: '20px 0', cursor: 'pointer' }}
//                 onClick={(event) => {
//                   if (item.subItems.length > 0) {
//                     handlePopoverOpen(event, index);
//                   } else {
//                     handleIconClick(item.name);
//                   }
//                 }}
//               >
//                 <ListItemIcon>
//                   {React.cloneElement(item.icon, { style: { color: iconColorRegular } })}
//                 </ListItemIcon>
//               </ListItem>
//             )
//           ))}
//         </List>
//       </Box>

//       {selectedIndex !== null && icons[selectedIndex]?.subItems.length > 0 && (
//         <Popover
//           ref={popoverRef}
//           open={open}
//           anchorEl={anchorEl}
//           onClose={handlePopoverClose}
//           anchorOrigin={{
//             vertical: 'top',
//             horizontal: 'right',
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'left',
//           }}
//         >
//           <Box sx={{ p: 2 }}>
//             <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', fontWeight: 600, fontSize: '17px' }}>
//               {icons[selectedIndex].name}
//             </Typography>
//             <Divider />
//             <List>
//               {icons[selectedIndex]?.subItems.map((subItem, subIndex) => (
//                 <ListItem button key={subIndex} onClick={() => handleIconClick(subItem.path)}>
//                   <ListItemIcon sx={{ minWidth: 30 }}>
//                     <LayersIcon sx={{ color: iconColorRegular }} />
//                   </ListItemIcon>
//                   <Typography sx={{ color: textColor, fontSize: '14px' }}>
//                     {subItem.name}
//                   </Typography>
//                 </ListItem>
//               ))}
//             </List>
//           </Box>
//         </Popover>
//       )}
//     </Drawer>
//   );
// };

// export default Dashboardsideicon;








import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  Box,
  Divider,
  IconButton,
  Collapse,
  Popover,
} from '@mui/material';
import KeyIcon from '@mui/icons-material/VpnKey';
import LayersIcon from '@mui/icons-material/Layers';
import SearchIcon from '@mui/icons-material/Search';
import AssessmentIcon from '@mui/icons-material/Assessment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../../../common/ThemeContext';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const Dashboardsideicon = () => {
  const { theme } = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [openSubItems, setOpenSubItems] = useState({}); // To track which items are expanded
  const [anchorEl, setAnchorEl] = useState(null); // For Popover
  const [selectedIndex, setSelectedIndex] = useState(null); // To track selected icon for Popover
  const navigate = useNavigate();

  const drawerWidth = isExpanded ? 240 : 70;

  const icons = [
    {
      name: 'Keywords',
      icon: <KeyIcon />,
      subItems: [
        { name: 'Overview', path: '/overview' },
        { name: 'Groups', path: '/groups' },
      ],
      highlighted: true,
    },
    {
      name: 'Landing Pages',
      icon: <LayersIcon />,
      subItems: [
        { name: 'Overview', path: '/landing-pages/overview' },
        { name: 'On-Page SEO', path: '/landing-pages/on-page-seo' },
        { name: 'Backlink Profile', path: '/landing-pages/backlink-profile' },
      ],
      highlighted: false,
    },
    { name: 'Keyword Research', icon: <SearchIcon />, subItems: [], highlighted: false },
    { name: 'Competitors', icon: <AssessmentIcon />, subItems: [], highlighted: false },
    { name: 'Notifications', icon: <NotificationsIcon />, subItems: [], highlighted: false },
    {
      name: 'Settings',
      icon: <SettingsIcon />,
      subItems: [
        { name: 'Packages', path: '/packages' },
        { name: "BackLinks", path: "/backlinks" }
      ],
      highlighted: false,
      bottom: true,
    },
  ];

  const backgroundColor = theme.palette.mode === 'dark' ? '#ffffff' : '#312b36';
  const textColor = theme.palette.mode === 'dark' ? '#000' : '#fff';
  const iconColorHighlighted = '#F76D1E';
  const iconColorRegular = theme.palette.mode === 'dark' ? '#888' : '#8a859f';

  const handleToggleSubItems = (index) => {
    setOpenSubItems((prevState) => ({
      // Collapse all sub-items except the selected one
      [index]: !prevState[index],
    }));
  };

  const handleIconClick = (path) => {
    navigate(path);
  };

  const toggleSidebar = () => {
    setIsExpanded((prevExpanded) => {
      if (prevExpanded) {
        setOpenSubItems({}); // Reset sub-items state when collapsing the sidebar
      }
      return !prevExpanded;
    });
  };

  const handlePopoverOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          ['& .MuiDrawer-paper']: {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor,
            color: textColor,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'width 0.3s ease',
            mt: 8,
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto', flex: 1, width: '100%' }}>
          <List>
            {icons.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  button
                  onClick={(event) => {
                    if (item.subItems.length > 0) {
                      if (isExpanded) {
                        handleToggleSubItems(index);
                      } else {
                        handlePopoverOpen(event, index);
                      }
                    } else {
                      handleIconClick(item.name);
                    }
                  }}
                  sx={{
                    justifyContent: isExpanded ? 'flex-start' : 'center',
                    padding: '20px 16px',
                    cursor: 'pointer',
                  }}
                >
                  <ListItemIcon sx={{ minWidth: isExpanded ? 40 : 'unset', justifyContent: 'center' }}>
                    {React.cloneElement(item.icon, {
                      style: { color: item.highlighted ? iconColorHighlighted : iconColorRegular },
                    })}
                  </ListItemIcon>
                  {isExpanded && (
                    <>
                      <ListItemText primary={item.name} sx={{ color: textColor }} />
                      {item.subItems.length > 0 && (
                        openSubItems[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />
                      )}
                    </>
                  )}
                </ListItem>
                {isExpanded && item.subItems.length > 0 && (
                  <Collapse in={openSubItems[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem, subIndex) => (
                        <ListItem
                          button
                          key={subIndex}
                          onClick={() => handleIconClick(subItem.path)}
                          sx={{ pl: 8 }}
                        >
                          <ListItemText primary={subItem.name} sx={{ color: textColor }} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
        <Divider />
        <IconButton
          onClick={toggleSidebar}
          sx={{
            position: 'absolute',
            top: "500px",
            left: isExpanded ? drawerWidth - 50 : 20,
            backgroundColor: iconColorRegular,
            color: "#fff",
            '&:hover': { backgroundColor: iconColorHighlighted },
            zIndex: 1000,
            width: '30px',
            height: '30px'
          }}
        >
          {isExpanded ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
        </IconButton>
      </Drawer>

      {/* Popover for collapsed sidebar */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, background: backgroundColor }}>
          <Typography variant="h6" gutterBottom sx={{ color: textColor, textAlign: 'center', fontWeight: 600, fontSize: '17px' }}>
            {icons[selectedIndex]?.name}
          </Typography>
          <Divider />
          <List>
            {icons[selectedIndex]?.subItems.map((subItem, subIndex) => (
              <ListItem button key={subIndex} onClick={() => handleIconClick(subItem.path)}>
                <Typography sx={{ color: textColor, fontSize: '14px' }}>
                  {subItem.name}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default Dashboardsideicon;





