import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const BannerSection = () => {
    return (
        <Box
            sx={{
                background: "linear-gradient(150deg, #002966 30%, #005AE2 90%)",
                padding: "50px 0",
                color: "#ffffff",
            }}
        >
            <Box sx={{ mx: "auto", maxWidth: "1200px", px: 3, mb: 5 }}>
                <Grid container justifyContent="space-between" alignItems="center" textAlign={'center'}>
                    <Typography variant="h3" fontWeight="bold" sx={{textAlign:'center'}}>
                       We Are Hiring

                    </Typography>

                </Grid>
            </Box>
        </Box>
    )
}

export default BannerSection