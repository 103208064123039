import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardMedia, CardContent } from '@mui/material';
import image1 from '../../../../assets/industries1.jpeg';
import image2 from '../../../../assets/industries2.jpeg';
import image3 from '../../../../assets/industries3.jpeg';
import image4 from '../../../../assets/industries4.jpeg';
import image5 from '../../../../assets/industries5.jpeg';
import image6 from '../../../../assets/industries6.jpeg';
import image7 from '../../../../assets/education1.jpeg';

const industries = [
    { title: 'Utilities', image: image1 },
    { title: 'RealState', image: image2 },
    { title: 'Travel', image: image3 },
    { title: 'Chemical', image: image4 },
    { title: 'B2B', image: image5 },
    { title: 'Retail', image: image6 },
    { title: 'Education', image: image7 },
];

const IndustriesSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalIndustries = industries.length;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % totalIndustries);
        }, 3000); // Change card every 3 seconds
        return () => clearInterval(interval);
    }, [totalIndustries]);

    return (
        <Box sx={{ textAlign: 'center', py: 5, backgroundColor: '#f8f8f8', overflow: 'hidden' }}>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
                Industries We Serve
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                Pioneering Excellence Across Industries with Tailored End-to-End Expertise and Solutions
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, overflow: 'hidden', position: 'relative' }}>
                <Box
                    sx={{
                        display: 'flex',
                        transform: `translateX(-${(currentIndex / totalIndustries) * 100}%)`,
                        transition: 'transform 0.5s ease-in-out',
                        width: `${totalIndustries * 200}%`, // Adjust width for seamless scrolling
                    }}
                >
                    {[...industries, ...industries].map((industry, index) => (
                        <Card
                            key={index}
                            sx={{
                                borderRadius: 2,
                                overflow: 'hidden',
                                boxShadow: 3,
                                flex: '0 0 16%',
                                mx: 1,
                                position: 'relative',
                            }}
                        >
                            <CardMedia
                                component="img"
                                height="300"
                                image={industry.image}
                                alt={industry.title}
                            />
                            <CardContent
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    // background: 'rgba(0, 0, 0, 0.5)',
                                    color: 'white',
                                    textAlign: 'center',
                                    padding: '8px',
                                }}
                            >
                                <Typography variant="h6" fontWeight="bold">
                                    {industry.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default IndustriesSection;
