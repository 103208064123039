import { Box } from '@mui/material'
import React from 'react'
import image from '../../../../../assets/facebook-ads-more-proof-1920x5371.jpg'

const FeedBack = () => {
    return (
        <Box sx={{justifyContent:'center',alignItems:'center',textAlign:"center",mt:8}}>
            <Box
                component="img"
                src={image}
                alt="Facebook Advertising Process"
                sx={{ width: '1200px', }}
            />
        </Box>
    )
}

export default FeedBack