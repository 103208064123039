import React from 'react';
import { Grid, Typography } from '@mui/material';

const HomeBanner = () => {
    return (
        <>
            <Grid
                sx={{
                    position: 'relative',
                    height: { xs: '50vh', sm: '60vh' },
                    overflow: 'hidden',

                }}
            >
                {/* Background Section */}
                <Grid
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: 'linear-gradient(to bottom, #271e2a, #332837, #4d3753)',
                        zIndex: 1,
                    }}
                />

                {/* Dark Layer Overlay */}
                <Grid
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        zIndex: 2,
                    }}
                />

                {/* Content Section */}
                <Grid
                    sx={{
                        position: 'relative',
                        zIndex: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#fff',
                        textAlign: 'center',
                        padding: { xs: '16px', sm: '24px', md: '32px' },
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    {/* Main Heading - split into two lines */}
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 'bold',
                            mb: 1,
                            mt: { xs: 8, md: 8 },
                            lineHeight: '1.2em',
                            fontSize: { xs: '2.1rem', sm: '1.8rem', md: '3.2rem' },
                            maxWidth: { xs: '90%', sm: '80%', md: '70%' },
                        }}
                    >
                        SEO Mitra Your Trusted Partner in
                    </Typography>
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 'bold',
                            mb: 2,
                            lineHeight: '1.2em',
                            fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' },
                            maxWidth: { xs: '90%', sm: '80%', md: '70%' },
                        }}
                    >
                        Backlinks & Keyword Tracking
                    </Typography>

                    {/* Body Paragraph */}
                    <Typography
                        variant="body1"
                        sx={{
                            mb: 4,
                            lineHeight: '1.6em',
                            fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' },
                            maxWidth: { xs: '90%', sm: '80%', md: '60%' },
                        }}
                    >
                        Empowering Your Online Growth with Precision SEO Tools
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default HomeBanner;
